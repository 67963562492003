//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import styled from 'styled-components'
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector ,  useDispatch  } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit, FaSearch, FaEye, FaEyeSlash, FaArrowCircleLeft } from 'react-icons/fa';
//■■■■■■■■■■■■■■■■■■■■ HELPERS

import { apiAppelEleveEdit } from '../../api/cmzApi'
import { diffMoments } 	from '../../helpers/fonctions'
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import Loading 				from '../_nav/Loading'
//import AppelEleveLigne 	from './AppelEleveLigne'
import TableAppelContainer	from '../appelShow/appelTable/TableAppelContainer'
import FormAppel 			from '../appelsIndex/FormAppel'
//■■■■■■■■■■■■■■■■■■■■ STYLES
import "./appelShow.scss"
//██████████████████████████████████████████████████████████████████████████████




export default function AppelShow({match, globalFetch, appIsFetching}) {

	const paramAppelId = (match && match.params && match.params.id) ? match.params.id : "XX"
	const appelId = isNaN(parseInt(paramAppelId)) ? 0 : parseInt(paramAppelId)

	const rAppel = useSelector((state) => state.rAppel)
	const rUser = useSelector((state) => state.rUser)


	const [selectedAppel, setSelectedAppel] = useState(null)

    useEffect(() => {
        if ( (rAppel.appel===null)   || (rAppel.appel.id !==appelId)    ){
			globalFetch({
				fetchUser: (rUser.user ? false : true),
				fetchAppels: false,
				appelId: appelId,
			})
    	}
    }, [appelId])

    return (
        <div className="app-page row col-12 pageDpAppelShow">



			{selectedAppel &&
				<FormAppel
					appel={selectedAppel}
					setAppel={setSelectedAppel}
				/>
			}




            <div className="page-header col-12">
                <div className="page-title">

					<NavLink  className="float-start " to={"/appel/"}	title="Retour à la liste des appels">
						<FaArrowCircleLeft />
					</NavLink>
					{ rAppel.appel ?
						<>
						 	<b>{Moment(rAppel.appel.dateAppel).format("dddd DD/MM/YYYY")}</b>
							&nbsp;<small>({ diffMoments(Moment(rAppel.appel.dateAppel)) })</small>
							<Button
								variant="warning" className="float-end btn-sm"
								to={"/appel/"}	title="Editer"
								onClick={()=>setSelectedAppel(rAppel.appel)}
							>
								<FaEdit />
							</Button>
						</>
						:
						<> #{ appelId }</>
					}
				</div>

				{ rAppel.appel &&
	                <h5 className="page-suuuuuuuuuuuuuuuuub-title text-center mb-2">

						Classes Prios:&nbsp;
				   		{rAppel.appel.prio6 && rAppel.appel.prio5 && rAppel.appel.prio4 && rAppel.appel.prio3
							?
								'Toutes'
							:
								<>
									{rAppel.appel.prio6 && <>6<sup>e</sup> </>}
									{rAppel.appel.prio5 && <>5<sup>e</sup> </>}
									{rAppel.appel.prio4 && <>4<sup>e</sup> </>}
									{rAppel.appel.prio3 && <>3<sup>e</sup> </>}
								</>
						}
					</h5>
				}

            </div>



			<div className="page-content row col-12">
				{ appIsFetching && <Loading /> }

				{ !appIsFetching &&  rAppel.appel &&
					<TableAppelContainer
						appel={rAppel.appel}
					/>
				}

				{ !appIsFetching &&  !rAppel.appel &&
					<>Hum hum: appel is nul !</>
				}

			</div>

 		</div>

    )



}
