//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████




export default function Place({table, num, appelEleves, place, setPlace, updateAe}) {

	const placeName= "T"+table+"P"+num


	function placeClick(){
		if (appelEleves.length>0){
			const ae = appelEleves[0]
			if(window.confirm(`Voulez vous enlever ${ae.eleve.nom} ${ae.eleve.prenom} de cette place ?`)){
				updateAe({...ae, place: null  })
				setPlace(null)
			}
			else{
				return null
			}
		}
		else{
			if(placeName===place){
				setPlace(null)
			}
			else{
				setPlace(placeName)
			}
		}

	}


	return(
		<StyledPlace
			onClick={()=>placeClick()}
			isSelected={place===placeName ? true : false}
		>
			{appelEleves.map((ae)=> {
				return(
					<StyledEleve key={'aePl-'+ae.id} service={ae.service}>
						<b>{nomPropre(ae.eleve.nom.substring(0,10))}</b> {ae.eleve.prenom} {ae.eleve.classe}
					</StyledEleve>
				)
			})}
			{appelEleves.length===0 && <span className='debug'>{placeName}</span> }
		</StyledPlace>
	)

	function nomPropre(string) {
	  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}



}

//█████████████████████████████████████████████████████████████


//█████████████████████████████████████████████████████████████ StyledComponents


const StyledPlace = styled.div`
	/*background-color:orange;*/
	border: 1px solid black;
	width:100%;
	height: 70px;
	overflow: hidden;
    /*white-space: nowrap;*/
	line-height: 80%;
	background-color: ${props => (props.isSelected ? `yellow` : `white`)};
	padding:3px;
	.debug{
		color:gray;
		font-size : 0.9em;
	}
`;

const StyledEleve = styled.span`
	font-size:0.8em;
	color: ${props => (props.service===1 ? `blue` : `green`)};
`;
