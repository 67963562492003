import { combineReducers } from 'redux'

//import appReducer from './appReducer'
import userReducer from './userReducer'
import appelReducer from './appelReducer'
import eleveReducer from './eleveReducer'

const rootReducer = combineReducers({
	//app: appReducer,
	rUser: userReducer,
	rAppel: appelReducer,
	rEleve: eleveReducer,
})

export default rootReducer
