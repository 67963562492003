// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal'

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import { diffMoments } from '../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 			from '../_nav/Loading'
import AppelsTable from './tableAppels/AppelsTable'
import FormAppel from './FormAppel'
//import FormAppel2 from './FormAppel2'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import "./appelsIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function TableAppelsIndex({globalFetch, appIsFetching}) {


	const rUser = useSelector((state) => state.rUser)
	const rAppel = useSelector((state) => state.rAppel)
	const [selectedAppel, setSelectedAppel] = useState(null)




  	const handleCloseFormAppel = () => setSelectedAppel(null);




	useEffect(() => {
		console.log('█ AppelsIndex - useEffect █')

	//	console.groupEnd()
		 if ( rAppel.appels === null ){
	 		console.log('█ exec █')
			globalFetch({
				fetchUser: (rUser.user ? false : true),
				fetchAppels: true,
				//appelId: appelId,
			})
		}
	}, [])



	function handleAddAppelClick(){
		const newAppel={
			id:null,
			dateAppel:Moment().format("YYYY-MM-DD"),
			prio6:false,
			prio5:false,
			prio4:false,
			prio3:false,
			commentaire:"",
			etabId: 10
		}
		setSelectedAppel(newAppel)
	}


    return (
        <div className="app-page row col-12 pageDpAppelsIndex">

			{/*
			{selectedAppel &&
			  	<FormAppel2
					appel={selectedAppel}
					setAppel={setSelectedAppel}
				/>
			}
			*/}

			{selectedAppel &&
				<FormAppel
					appel={selectedAppel}
					setAppel={setSelectedAppel}
					fromAppelIndex={true}
				/>
			}

            <div className="page-header col-12">
                <div className="page-title">
					Liste des appels Cantine
				</div>
            </div>




            <div className="page-content row col-12">
				{ !rAppel.appels ?
					<Loading />
				:
					<>
						<div className="mb-2">
					      	<Button variant="primary" onClick={()=>handleAddAppelClick()} className="float-end">
					        	<FaPlus /> Ajouter un appel
					      	</Button>
						</div>
						<AppelsTable
						appels={rAppel.appels}
						setSelectedAppel={setSelectedAppel}
					/>
					</>
				}
            </div>

			{/*
	        <div className="row col-12 mt-5">
				<RenderHelp />
				<RenderTodo />
			</div>
			*/}


        </div>
    )



	function RenderHelp(){
		return(
            <div className="col-lg-6 col-12 help">
				<h1>Aide</h1>

				<ul>
					<li>Cliquez sur une date dans le tableau ci-dessus pour voir ou faire l'appel.</li>
					<li>Cliquez sur le bouton <FaEdit /> pour modifier un appel.</li>
				</ul>
				<h2>Si l'appel du jour n'apparait pas dans la liste:</h2>
				<ul>
					<li>Cliquez sur "<b>Ajouter un appel</b>".</li>
					<li>Dans la nouvelle fenêtre, laissez la date par defaut, choisissez les classes prios et validez.</li>
				</ul>

            </div>
		)
	}



	function RenderTodo(){
		return(
            <div className="col-lg-6 col-12  todo">
				<h1>Todo</h1>
				<ul>
					<li>appel.date = uniq by etab (onCreate & onEdit)</li>
					<li>Message: "L'appel d'aujourd'hui n'a pas été crée"</li>
					<li>bug sur humanDate futur (j+1)</li>
					<li>Charger automatiquement l'appel du jour s'il existe</li>
				</ul>
            </div>
		)
	}

}
