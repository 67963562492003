// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal'

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { diffMoments } from '../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import Loading 			from '../_nav/Loading'
//import AppelsTable from './tableAppels/AppelsTable'
//import FormAppel from './FormAppel'
//import FormAppel2 from './FormAppel2'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./appelsIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function Foyer({globalFetch, appIsFetching}) {




    return (
        <div className="app-page row col-12 pageFoyer">


            <div className="page-header col-12">
                <div className="page-title">
					Foyer
				</div>
            </div>


            <div className="page-content row col-12">

	            <h5 className="col-12 text-danger text-center">
					<b>W</b>ork <b>I</b>n <b>P</b>rogress
	            </h5>

				<h3>Todo</h3>
				<ul>
					<li>Index des appels foyer</li>
					<li>index des éleves inscrits au foyer + ajout/edit</li>
					<li>Inscrire élève pour un appelFoyer</li>
					<li>Faire l'appel</li>
				</ul>
            </div>



        </div>
    )




}
