// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■



// █████████████████████████████████████████████████████████████████████████████

import React from "react";
import { useState } from 'react'
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import { useSelector,  useDispatch  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';


export default function TableResume() {

	let appel = useSelector((state) => state.rAppel.appel)
	const dayOfWeek = Moment(appel.dateAppel).format("dddd")
	const searchedText = ""

	return(
		<table className="table table-bordered table-sm table-appel-resume">

			<RenderTHead />

			<tbody>
				{ appel.appelEleves.filter(ae => (ae.eleve.regime==="DP" && ae.eleve.classe !=="AED")).map((ae, a) => {
					return <RenderTrEleve ae={ae} key={'appel-eleve-dp-'+a} />
				})}

				<RenderTableResumeTrVide titre="Elèves Externes:" />

				{ appel.appelEleves.filter(ae => (ae.eleve.regime!=="DP" && ae.eleve.classe !=="AED")).map((ae, a) => {
					return <RenderTrEleve ae={ae}  key={'appel-eleve-ext-'+a} />
				})}

				<RenderTableResumeTrVide titre="AED:" />

				{ appel.appelEleves.filter(ae => (ae.eleve.classe ==="AED")).map((ae, a) => {
					return <RenderTrEleve ae={ae}  key={'appel-aed-'+a} />
				})}
			</tbody>

			{searchedText ==="" &&
				<tfoot>
				    <tr>
				      <td colSpan="8">Total: xxx élèves sans carte</td>
				    </tr>
			  	</tfoot>
			}

		</table>

	)


	function RenderTHead(){
		return (
		  	<thead>
				<tr>
				   	<th colSpan="8">
					   	<b>{Moment(appel.dateAppel).format("dddd DD/MM/YYYY")}</b>
					   	<div className="float-right">
							Prios:&nbsp;
							<b>
								{appel.prio6 && "6e "}
								{appel.prio5 && "5e "}
								{appel.prio4 && "4e "}
								{appel.prio3 && "3e "}
							</b>
						</div>
				   	</th>
				</tr>

				{appel.commentaire &&
					<tr className="trCommentaire">
					   	<th colSpan="8">
						   	<b>Commentaire: </b>
						   	{appel.commentaire}
					   	</th>
					</tr>
				}

				<tr>
				   <th>Elève/AED</th>
				   <th>Service</th>
				   <th>Carte</th>
				   	{/*<th>Commentaire</th> */}
				   <th>Classe</th>
				   <th>Régime</th>
				   <th>Service<br />thérorique</th>
				   <th>prio</th>
				</tr>
			</thead>
		)
	}

	function RenderTrEleve({ae}){
		if(searchedText !==""){
			let strEleve = (ae.eleve.nom + " " + ae.eleve.prenom).toLowerCase()
			if (!strEleve.includes(searchedText.toLowerCase())){
				return null
			}
		}

		let serviceTheorique = 2
		let trClassName = "trEleve " + (ae.service ? ("trService"+ae.service) : "")


		let prioToday = null
		switch(dayOfWeek){
			case "lundi":
				prioToday= ae.eleve.prioLundi
				break
			case "mardi":
				prioToday= ae.eleve.prioMardi
				break
			case "mercredi":
				prioToday= ae.eleve.prioMercredi
				break
			case "jeudi":
				prioToday= ae.eleve.prioJeudi
				break
			case "vendredi":
				prioToday= ae.eleve.prioVendredi
				break
			default:
				console.log("DEFAUT SWICH CASE !!!!")
		}

		if (prioToday !== null && prioToday !== ""){
			serviceTheorique=1
		}

		const niveau = ae.eleve.classe.charAt(0)
		if(
			(niveau==="6" && appel.prio6) ||
			(niveau==="5" && appel.prio5) ||
			(niveau==="4" && appel.prio4) ||
			(niveau==="3" && appel.prio3) ){
				serviceTheorique=1
		}






		//let isClassePrio = dayOfWeek==="Lundi" &&

		//console.log("-----------------------+++")
		//console.log(dayOfWeek)


		return (
			<tr className={trClassName}>
				<td className="colEleveNom"><b>{ae.eleve.nom}</b> {ae.eleve.prenom}</td>
				<td className={"service"+ae.service}>{ae.service ? "Service "+ ae.service : "Abs" } </td>
				<td className={ae.sansCarte && "sansCarte"}>{ae.sansCarte && "Sans Carte" }</td>
				{/*<td>{ae.commentaire}</td>*/}
				<td>{ae.eleve.classe}</td>
				<td>{ae.eleve.regime}</td>
				<td className={"serviceTheo"+serviceTheorique}>{serviceTheorique}</td>
				<td>{prioToday}</td>
			</tr>
		)
	}


	function RenderTableResumeTrVide({titre}){
		if(searchedText !==""){
			return null
		}

		return (
			<tr className="trVide">
				<td colSpan="8">&nbsp;&nbsp;{titre}</td>
			</tr>
		)
	}



}
