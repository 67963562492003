// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■







// █████████████████████████████████████████████████████████████████████████████


import React from "react";
import { useState } from 'react'
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';


export default function TrFaireAppel({ae, searchedText, currentService, showAll, appel, updateAe}) {

	//console.log(appel.appelEleves)
	const sortedAes = appel.appelEleves.sort((a,b) => (a.eleve.nom > b.eleve.nom) ? 1 : ((b.eleve.nom > a.eleve.nom) ? -1 : 0))
	const sortedAppelEleves = sortedAes.filter(ae => (ae.eleve.classe !=="AED"))
	const sortedAppelAeds = sortedAes.filter(ae => (ae.eleve.classe ==="AED"))

	const dayOfWeek = Moment(appel.dateAppel).format("dddd")

	function handleSansCarteClick(ae){
		let newSansCarte =  !ae.sansCarte
		let newService = ae.service

		if (newSansCarte && !newService){
			newService = currentService
		}


		updateAe({...ae,
			sansCarte: newSansCarte,
			service: newService
		})
	}



		if(searchedText !==""){
			let strEleve = (ae.eleve.nom + " " + ae.eleve.prenom).toLowerCase()
			if (!strEleve.includes(searchedText.toLowerCase())){
				return null
			}
		}

		if(!showAll && ae.service !==null && searchedText ===""){
			return null
		}



		let smallTr = (
			ae.service !==null ||
			(currentService===1 && ae.serviceTheorique===2) ||
			!ae.eleve.actif
		//	|| ae.eleve.regime !=="DP"
		)



		let trClassName = " trEleve "
		trClassName += (ae.service ? (" trService"+ae.service) : " trService0 ")
		trClassName += ( smallTr && ' smallTr ')
		trClassName += (currentService===1 && ae.serviceTheorique===2 && " trGreen ")
		trClassName += ae.eleve.actif ? "" : " trInactif "
		//trClassName += ae.eleve.regime==="DP" ? "" : " trExterne "

		return (
			<tr className={trClassName}>

				<td className="colEleveNom">
				<b>{ae.eleve.nom}</b> {ae.eleve.prenom}	({ae.eleve.classe})
					{ae.eleve.regime !=="DP" &&
						<div className="badge bg-danger">{ae.eleve.regime}</div>
					}
					{ae.prioToday &&
						<span className={"badge bg-"+(ae.prioToday==="FSE" ?"info":"primary")}>{ae.prioToday}</span>
					}
					{!ae.eleve.actif&&
						<span className="badge bg-secondary">Inactif</span>
					}
				</td>

				<td className={"service"}>
					{ae.service && ae.service !== currentService ?
						<>{ae.service}</>
						:
						(
						<>
							{currentService === 1 &&
								<Button
									className={"bt-service-1 col-12 " + (ae.service===1 ? " btn-primary "  : " btn-secondary ")  +  (smallTr && " btn-sm ")}
									onClick={ () => updateAe({...ae, service: (ae.service===1 ? null : 1 )  })}
								>
									1
								</Button>
							}
							{currentService === 2 &&
								<Button className={"bt-service-2 col-12 " + (ae.service===2 ? " btn-success "  : " btn-secondary ")  +  (smallTr && "  btn-sm  ")}
								onClick={ () => updateAe({...ae, service: (ae.service===2 ? null : 2 )  })}
								>
									2
								</Button>
							}
						</>


						)
					}

				</td>

				<td>
					{(ae.service && ae.service !== currentService) ?
						ae.sansCarte && "SC"
						:
					<Button className={"btsanscarte col-12 " + (ae.sansCarte ? " btn-danger "  : " btn-secondary ") }
						onClick={ () => handleSansCarteClick(ae)}
					>
						SC
					</Button>
					}
				</td>

				{/*
				<td>{ae.commentaire}</td>
				<td>{serviceTheorique}</td>
				*/}
			</tr>
		)





}
