// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
//import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import styled from 'styled-components'

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { apiMesEtablissementsNew } from "../../api/cmzApi";

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import LoginForm from '../login/LoginForm'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./home.scss"


// █████████████████████████████████████████████████████████████████████████████


export default function About() {


	const changelogItems=[
		{	date: "2021/12/01",		content:"Plan de table",		},
		{	date: "2021/11/17",		content:"Page Elève : infos + liste des appels de l'élève",		},
		{	date: "2021/11/17",		content:"Page Statistiques: élèves sans cartes, absents, externes",		},
		{	date: "2021/11/05",		content:"Formulaire d'Appel: Validation (date/prios) + datePicker",		},
		{	date: "2021/11/04",		content:"Téléchargement de l'appel cantine au format excel",		},
	]


	const todoItems=[
	{	content:
			<>Plan de table:
				<ul>
					<li>Placer les élèves par tables + liste eleves (filtre service/niveau)</li>
					<li>Mettre les plans dans une 2e feuille du fichier excel</li>
					<li></li>
				</ul>
			</>,
	},
		{	content:"Plusieurs commentaires (ManyToOne) pour les appels/appelEleve/Eleves",		},
		{	content:"Foyer : inscription + paiement + appels",		},
		{	content:
				<>EleveShow:
					<ul>
						<li>Afficher les infos d'un élève (cantine et foyer)</li>
						<li>liste des appels cantine (present/absent) et sans carte</li>
						<li>Infos Foyer</li>
						<li>Appels Foyer</li>
					</ul>
				</>,
		},
	]




    return (
        <div className="app-page row col-12">


            <div className="page-header col-12 p-3">
                <div className="page-title">About cmzDp</div>
            </div>


            <div className="page-content page-content-transparant row col-12">

				<div className="col-12 mb-5">
					<h5 className="mb-5">Tuto</h5>

					<section className="ps-4 mb-5">
						<h6>Mettre à jour la liste des élèves DP + prios</h6>
						<p className="ps-4">
							Rendez-vous dans le menu "<b>Liste des élèves</b>" pour ajouter les nouveaux élèves DP et editer les jours prios (DF, AS, LCE...).<br />
							Cliquez sur le nom d'un élève pour vois sa fiche.
							Allez dans le menu "<b>Statistiques</b>" pour voir les problèmes (externe, abs, sans carte)
						</p>
					</section>


					<section className="ps-4 mb-5">
						<h6>Créer l'appel du jour</h6>
						<p className="ps-4">
							Dans le menu "<b>Appels Cantine</b>", verifiez que l'appel du jour n'a pas déjà été créer. (Il faut actualiser la page s'il a été créer sur un autre PC/Tel.)<br />
							Cliquez sur <b>Ajouter un appel</b> et laissez vous guider.
						</p>
					</section>


					<section className="ps-4 mb-5">
						<h6>Faire l'appel (au petit portail)</h6>
						<p className="ps-4">
							Dans le menu "<b>Appels Cantine</b>", cliquez sur l'appel que vous venez de créer (actualisez la page si besoin).<br />
							Cliquez sur le bouton <b>1er serv.</b> ou <b>2e serv.</b><br />
							Cliquez sur le bouton <b>1</b> ou <b>2</b> à coté de chaque élève present. (re-cliquez sur le bouton pour annuler)<br />
							Pour un élève sans carte, vous pouvez directement cliquer sur le bouton <b>SC</b> (le service se mettra automatiquement)<br />
							.....<br />
							.....commentaires<br />
							Verifiez qu'il ne manque pas d'élèves prios (regardez tout en bas de la page)<br />
							Si l'appel a été fait sur 2 téléphones differents, il faut actualiser la page pour tout voir !<br />

						</p>
					</section>


					<section className="ps-4 mb-5">
						<h6>Les Sans carte</h6>
						<p className="ps-4">
							Arrivés à la cantine, les élèves avec carte passent en 1er.<br />
							A la fin, vérifiez que le nombre délèves restants, corresponde au nombre d'élèves notés sans carte (En bas de la page) (actualisez si besoin)<br />
							Si ce n'est pas le cas, ajoutez les élèves sans carte.<br />
						</p>
					</section>


					<section className="ps-4 mb-5">
						<h6>Faire le plan (WorkInProgress)</h6>
						<p className="ps-4">
							Faire asseoir les élèves par niveau.
							Cliquez sur le bouton "<b>Plan</b>"
							.....<br />
						</p>
					</section>

					<section className="ps-4 mb-5">
						<h6>Envoyer mail</h6>
						<p className="ps-4">
							sur un pc....<br />
							.....<br />
							.....commentaires<br />
						</p>
					</section>

				</div>


				<div className="col-md-6 col-12">
					<h5 className="mb-5">Journal des modifications</h5>
					{changelogItems.map((item)=>(
						<StyledChangelogItem>
							<div className="date">{item.date}</div>
							<div className="content">{item.content}</div>
						</StyledChangelogItem>
					))}
				</div>

				<div className="col-md-6 col-12">
					<h5 className="mb-5">Todo</h5>
					{todoItems.map((item)=>(
						<StyledTodoItem>
							<div className="content">{item.content}</div>
						</StyledTodoItem>
					))}
				</div>




            </div>
        </div>
    )


}

const StyledItem= styled.div`
	/*background-color: green;*/
	margin: 4px;
	padding: 10px;
	border-right: 1px solid gray;
	border-bottom: 1px solid gray;
	li{		margin:0px;	}
`;

const StyledChangelogItem= styled(StyledItem)`
	border-color:blue;
	.date{ color:blue;}
`;
const StyledTodoItem= styled(StyledItem)`
	border-color:red;
`;
