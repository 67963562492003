
import './loading.scss'

/*
*	props:
*		-	showMessage	:	boulean		- defaut: true
*		-	message	:		String		- defaut: "Chargement..."
*
*/
export default function Loading(props) {

	const showMessage = props.showMessage !==undefined  ? props.showMessage : true
	const message = props.message !==undefined  ? props.message : "Chargement..."


	return (
		<div className="loader">
			{ showMessage &&
				<div className="message">
					{message}
				</div>
			}
			<div className="Knight-Rider-loader animate">
				<span className="Knight-Rider-bar"></span>
				<span className="Knight-Rider-bar"></span>
				<span className="Knight-Rider-bar"></span>
			</div>
		</div>
	)
}
