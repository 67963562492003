// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';
import styled from 'styled-components'
// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { diffMoments } from '../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import Loading 			from '../_nav/Loading'
//import AppelsTable from './tableAppels/AppelsTable'
//import FormAppel from './FormAppel'
//import FormAppel2 from './FormAppel2'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./appelsIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function EleveInfos({eleve}) {




    return (
        <div className="row col-12">

			<div className="row col-6">


				<StyledLigne><StyledLabel>id:</StyledLabel><StyledValue>{eleve.id}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>nom:</StyledLabel><StyledValue>{eleve.nom}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>prenom:</StyledLabel><StyledValue>{eleve.prenom}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>classe:</StyledLabel><StyledValue>{eleve.classe}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>regime:</StyledLabel><StyledValue>{eleve.regime}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>actif:</StyledLabel><StyledValue>{eleve.actif ? "oui" : "non"}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>numeroCarte:</StyledLabel><StyledValue>{eleve.numeroCarte}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>commentaire:</StyledLabel><StyledValue>{eleve.commentaire}</StyledValue></StyledLigne>

				<h4>Prio:</h4>
				<StyledLigne><StyledLabel>Lundi:</StyledLabel><StyledValue>{eleve.prioLundi}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Mardi:</StyledLabel><StyledValue>{eleve.prioMardi}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Jeudi:</StyledLabel><StyledValue>{eleve.prioJeudi}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Vendredi:</StyledLabel><StyledValue>{eleve.prioVendredi}</StyledValue></StyledLigne>
				<h4>Foyer:</h4>
				<StyledLigne><StyledLabel>Inscrit:</StyledLabel><StyledValue>{eleve.foyerInscrit ? "oui" : "non"}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Montant payé:</StyledLabel><StyledValue>{eleve.foyerPaiementMontant}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Moyen de Paiement:</StyledLabel><StyledValue>{eleve.foyerPaiementMoyen}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Paiement Commentaire:</StyledLabel><StyledValue>{eleve.foyerPaiementCommentaire}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Commentaire:</StyledLabel><StyledValue>{eleve.foyerCommentaire}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Attribut1:</StyledLabel><StyledValue>{eleve.foyerAttribut1}</StyledValue></StyledLigne>
				<StyledLigne><StyledLabel>Attribut2:</StyledLabel><StyledValue>{eleve.foyerAttribut2}</StyledValue></StyledLigne>


			</div>

			<div className="row col-6">

				<h5 className="m-4">{eleve.appelEleves.length} appels cantine pour {eleve.prenom}:</h5>
				todo: trier par date !!!<br /><br />

				{eleve.appelEleves.map(ae=>
					<div key={ae.id} className="col-12">
						{ae.appel.dateAppel}
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						{ae.service ?
							<span>service {ae.service}</span>
							:
							<span className="text-danger">Absent</span>
						}
						{ae.sansCarte && <span className="text-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sans Carte</span>}
					</div>

				)}


			</div>


        </div>
    )




}

// █████████████████████████████████████████████████████████████████████████████



const StyledLigne = styled.div`
  	color: blue;
	display: inline-block;
`;
const StyledLabel = styled.div`
  	color: green;
	display: inline-block;
	min-width: 70px;
	margin-right: 10px;
`;
const StyledValue = styled.div`
	font-weight: bold;
  	color: red;
	display: inline-block;
`;
