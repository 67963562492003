// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■



// █████████████████████████████████████████████████████████████████████████████

import React from "react";
import { useState } from 'react'
import { useSelector,  useDispatch  } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';

import TrFaireAppel from './TrFaireAppel';

export default function TableFaireAppel({ appel, currentService, searchedText="", updateAe, showAll=true}) {


	const appelEleves = appel.appelEleves.filter(ae => (ae.eleve.classe !=="AED"))
	const appelAeds = appel.appelEleves.filter(ae => (ae.eleve.classe ==="AED"))

	function handleSansCarteClick(ae){
		let newSansCarte =  !ae.sansCarte
		let newService = ae.service

		if (newSansCarte && !newService){
			newService = currentService
		}


		updateAe({...ae,
			sansCarte: newSansCarte,
			service: newService
		})
	}


	return(

		<table className="table table-bordered table-sm table-faire-appel mb-5">

			<RenderTHead />

			<tbody>
				{ appelEleves.map((ae, a) => {
					//	return <RenderTrEleve ae={ae}  key={'appel-eleve-'+a} />
					return <TrFaireAppel
						ae={ae}
						key={'appel-aed-'+a}
						searchedText={searchedText}
						currentService={currentService}
						showAll={showAll}
						appel={appel}
						updateAe={updateAe}

					/>
				})}

				<RenderTableResumeTrVide titre="-------------- AED: --------------" />

				{ appelAeds.map((ae, a) => {
					//return <RenderTrEleve ae={ae}  key={'appel-aed-'+a} />
					return <TrFaireAppel
						ae={ae}
						key={'appel-aed-'+a}
						searchedText={searchedText}
						currentService={currentService}
						showAll={showAll}
						appel={appel}
						updateAe={updateAe}

					/>
				})}
			</tbody>
			{/*
			{searchedText ==="" &&
				<tfoot>
				    <tr>
				      <td colSpan="3">Total: xxx élèves sans carte </td>
				    </tr>
			  	</tfoot>
			}
			*/}
		</table>
	)


	function RenderTHead(){
		return (
		  	<thead>
				<tr>
				   	<th colSpan="3">
					   	<b>{Moment(appel.dateAppel).format("dddd DD/MM/YYYY")}</b>
					   	<div className="float-end">
							Prios:&nbsp;
							<b>
								{appel.prio6 && "6e "}
								{appel.prio5 && "5e "}
								{appel.prio4 && "4e "}
								{appel.prio3 && "3e "}
							</b>
						</div>
				   	</th>
				</tr>

				{appel.commentaire &&
					<tr className="trCommentaire">
					   	<th colSpan="3">
						   	<b>Commentaire: </b>
						   	{appel.commentaire}
					   	</th>
					</tr>
				}
				{/*
				<tr>
				   <th>Elève/AED</th>
				   <th>Service</th>
				   <th>Carte</th>
				   {/*
				   <th>Commentaire</th>
				   <th>Service<br />thérorique</th>
				   * /}
				</tr>
				*/}
			</thead>
		)
	}

	function RenderTrEleve({ae}){
		if(searchedText !==""){
			let strEleve = (ae.eleve.nom + " " + ae.eleve.prenom).toLowerCase()
			if (!strEleve.includes(searchedText.toLowerCase())){
				return null
			}
		}

		if(!showAll && ae.service !==null && searchedText ===""){
			return null
		}

		let smallTr = (ae.service !==null || (currentService===1 && ae.serviceTheorique===2) )

		let trClassName = " trEleve " + (ae.service ? (" trService"+ae.service) : " trService0 ") + ( smallTr && ' smallTr ') + (currentService===1 && ae.serviceTheorique===2 && " trGreen ")

		return (
			<tr className={trClassName}>

				<td className="colEleveNom">
				<b>{ae.eleve.nom}</b> {ae.eleve.prenom}	({ae.eleve.classe})
					{/*
					{(currentService===1 && ae.serviceTheorique===2) &&
						<span className="badge bg-success ml-2">2eme service</span>
					}
					*/}
					{ae.eleve.regime !=="DP" &&
						<span className="badge bg-danger ml-2">{ae.eleve.regime}</span>
					}
					{ae.prioToday&&
						<span className="badge bg-primary ml-2">{ae.prioToday}</span>
					}

				</td>

				<td className={"service"}>
					{ae.service && ae.service !== currentService ?
						<>Service {ae.service}</>
						:
						(
						<>
							{currentService === 1 &&
								<Button
									className={"col-12 " + (ae.service===1 ? " btn-primary "  : " btn-secondary ")  +  (smallTr && " btn-xs ")}
									onClick={ () => updateAe({...ae, service: (ae.service===1 ? null : 1 )  })}
								>
									1
								</Button>
							}
							{currentService === 2 &&
								<Button className={"col-12 " + (ae.service===2 ? " btn-success "  : " btn-secondary ")  +  (smallTr && " btn-xs ")}
								onClick={ () => updateAe({...ae, service: (ae.service===2 ? null : 2 )  })}
								>
									2
								</Button>
							}
						</>


						)
					}

				</td>

				<td>

					{ae.service && ae.service !== currentService ?
						<>{ae.sansCarte && "Sans Carte"}</>
					:
						<Button className={"col-12 " + (ae.sansCarte ? " btn-danger "  : " btn-secondary ")  +  (smallTr && " btn-xs ")}
							onClick={ () => handleSansCarteClick(ae)}
						>
							SC
						</Button>
					}
				</td>

				{/*
				<td>{ae.commentaire}</td>
				<td>{serviceTheorique}</td>
				*/}
			</tr>
		)
	}


	function RenderTableResumeTrVide({titre}){
		if(searchedText !==""){
			return null
		}

		return (
			<tr className="trVide">
				<td colSpan="3">{titre}</td>
			</tr>
		)
	}



}
