// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
//import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

//import { diffMoments } from '../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import AppelTr from './AppelTr'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./appelsIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function AppelsTable({appels, setSelectedAppel}) {

    return (
		<table className="tableAppels zxTable">

			<thead>
				<tr className="trCommentaire">
					<th colSpan="5">
						Liste des appels Cantine
					</th>
				</tr>
				<tr className="zxTrColumnsHeader">
				   <th className="text-end">Date</th>
				   <th>Date</th>
				   <th className="text-center">Prios</th>
				   <th>Commentaire</th>
				   {/*
				   <th>terminée</th>
				   <th>mail</th>
				   */}
   				   <th></th>
				</tr>
			</thead>

			<tbody>

				{ appels.map((appel, a) => {
					return	<AppelTr
							key={'appel-'+a}
							setSelectedAppel={setSelectedAppel}
							appel={appel}
					 	/>
				})}

			</tbody>
			{/*
			<tfoot>
				<tr>
				  <td colSpan="5">xxxxxxxxxxxxxxxxxxxxxxx</td>
				</tr>
			</tfoot>
			*/}


		</table>
    )



}
