//■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import Button from 'react-bootstrap/Button';
//█████████████████████████████████████████████████████████████████████████████



export default function AppelModesButtons({currentMode, setMode}) {


    return (
		<div className="d-flex col-12 mb-5 justify-content-evenly">
			<RenderButtonMode	text={<>Résumé</>}					mode="RESUME"		color="info"			/>
			<RenderButtonMode	text={<>1<sup>er</sup> serv.</>}	mode="APPEL_1"		color="primary"			/>
			<RenderButtonMode	text={<>2<sup>eme</sup> serv.</>}	mode="APPEL_2"		color="success"			/>
			<RenderButtonMode	text={<>Plan</>}					mode="PLAN"			color="info"		/>
			<RenderButtonMode	text={<>eMail</>}					mode="MAIL_EXCEL"	color="info"		/>
		</div>
    )



	function RenderButtonMode({text, mode, color}){
		return(
				<Button
					className="ml-2"
					variant={ currentMode===mode ? color  : "secondary" }
					onClick={()=>setMode(mode)}
				>
					{text }
				</Button>

		)
	}



}
