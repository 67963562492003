//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



//import TrFaireAppel from './TrFaireAppel';

export default function BoutonTable({ table,  selectedTable, setSelectedTable, setPlace, appelEleves}) {

	const aes = appelEleves.filter(ae => ae.place && ae.place.startsWith(`T${table}`))
	const countTableServ1 = aes.filter(ae => ae.service === 1).length
	const countTableServ2 = aes.filter(ae => ae.service === 2).length

	function tableClick(){
		setSelectedTable(table)
		setPlace(null)
	}


	return(
		<Button
			className="me-1"
			variant={ selectedTable===table ? 'info'  : "secondary" }
			onClick={()=>tableClick()}
		>
			T{table}
			{countTableServ1>0 && <small> (serv.1: {countTableServ1} élèves)</small> }
			{countTableServ2>0 && <small> (serv.2: {countTableServ2} élèves)</small> }
		</Button>
	)
}


//█████████████████████████████████████████████████████████████ StyledComponents

const StyledXxxxxxxxxxx = styled.div`

`;
