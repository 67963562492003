const initialState = {
	eleves: null,
	eleve: null,
};


export default function eleveReducer(state = initialState, action) {


    switch (action.type) {

		case "SET_ELEVES":			return { ...state, eleves:		action.payload }
		case "SET_ELEVE":			return { ...state, eleve:		action.payload }

		case "CREATE_ELEVE":{
			const newEleves = state.eleves.concat(action.payload)
			return { ...state, eleves: 		newEleves }
		}

		case "UPDATE_ELEVE":{
			const newEleves = state.eleves.map(el => (el.id === action.payload.id ? action.payload : el))
			return { ...state, eleves: 		newEleves }
		}

		case "DELETE_ELEVE":{
			let newEleves = state.eleves.filter(oldEleve => oldEleve.id !==  action.payload)
			return { ...state, eleves: 		newEleves }
		}

        default:					return state
    }



};
