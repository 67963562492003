
import Moment from 'moment';



	export function diffMoments(momentDate){
		let diffDays = Moment().diff(momentDate, 'days')
		let str = ""
		if (diffDays === 0) {
			str = "Aujourd'hui"
		}
		else if (diffDays === 1) {
			str = "Hier"
		}
		else if (diffDays > 1) {
			str = "Il y a "+ diffDays+" jours"
		}
		else if (diffDays === -1) {
			str = "Demain"
		}
		else  {
			str = "Dans "+ -diffDays+" jours"
		}
		return(str)

	}


// #####################################################	HEURES


/*
 *		Renvoi la durée en minutes entre 2 string_Heures '12:00'
 *		("08:00", "09:30")		==>		90
 */
export function calcDureeMinutesFromStrHeureDebutToStrHeureFin(strHeureDebut, strHeureFin){
		const dureeMinutes = calcMinutesFromMidnight(strHeureFin) - calcMinutesFromMidnight(strHeureDebut)

		return  dureeMinutes
}


/*
 *		Renvoi le nombre de minutes depuis minuit à partir d'une heure en string
 *		"08:50"		==>		8x60+50
 */
export function calcMinutesFromMidnight(heureStr){
		let h = parseInt(heureStr.split(':')[0]);
		let m = parseInt(heureStr.split(':')[1]);
	   	let minutesFromMidnight = h * 60 + m;
		return  minutesFromMidnight
}


/*
 *		Renvoi une heure en string à partir du nombre de minutes
 *		8x60+50		==>		"08:50"
 */
export function minutesToStringHeuresMinutes(intMinutes, hourSeparator=":", hideNullMinutes=false){
		let m = intMinutes%60;
		let h = (intMinutes-m)/60;


		let mStr = m>9 ? m : "0"+m
		if(hideNullMinutes && m===0){
			mStr=""
		}


		return  h + hourSeparator + mStr
}



export function calcPercent(val, total){
		let percent = val * 100 / total
		return  percent
}


// #####################################################	Postes


export function getGroupesFromPostes(postes){
	let groupes = []
	postes.forEach((poste, p) => {
		//let groupe = (poste.groupe !== null && poste.groupe !== "") ? poste.groupe : "Sans groupeXX"
		if(!groupes.includes(poste.groupe)){
			groupes.push(poste.groupe)
		}
	});
	let sortedGroupes = groupes.sort((a,b) => (a > b) ? 1 : ((b > a) ? -1 : 0))

	return sortedGroupes
}







// #####################################################	XXXXXXXXX
