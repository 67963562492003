// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import {useRef }  from "react";
import { useSelector,  useDispatch  } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit, FaSearch, FaEye, FaEyeSlash, FaArrowCircleLeft } from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { apiAppelEleveEdit } from '../../api/cmzApi'
//import { diffMoments } 	from '../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
//import Loading 			from '../_nav/Loading'
//import AppelEleveLigne 	from './AppelEleveLigne'
//import TableAppelContainer	from './appelTable/TableAppelContainer'
//import FormAppel from '../appelsIndex/FormAppel'
import EleveInfos from '../eleve/EleveInfos'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■

//import "./appelShow.scss"


// █████████████████████████████████████████████████████████████████████████████






export default function EleveShow({match, globalFetch, appIsFetching}) {

	const paramEleveId = (match && match.params && match.params.id) ? match.params.id : "XX"
	const eleveId = isNaN(parseInt(paramEleveId)) ? 0 : parseInt(paramEleveId)

	const eleve = useSelector((state) => state.rEleve.eleve)
	const rUser = useSelector((state) => state.rUser)



    useEffect(() => {
        if ( (eleve===null)   || (eleve.id !==eleveId)    ){
			globalFetch({
				fetchUser: (rUser.user ? false : true),
				fetchAppels: false,
				eleveId: eleveId,
			})
    	}
    }, [eleveId])

    return (
        <div className="app-page row col-12 pageEleveShow">


            <div className="page-header col-12">

				<NavLink  className="float-start " to={"/eleves/"}	title="Retour à la liste des élèves">
					<FaArrowCircleLeft />
				</NavLink>
                <div className="page-title">

					{ eleve ?
						<>
						 	<b>{eleve.nom}</b> {eleve.prenom} ({eleve.classe})
						</>
						:
						<>eleve #{eleveId}</>
					}
				</div>


            </div>



			<div className="page-content row col-12">

	      
				{ !eleve ?
					<>
					 	Chargement
					</>
					:
					<>
						<EleveInfos eleve={eleve} />
					</>
				}

			</div>

 		</div>

    )



}
