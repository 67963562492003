const initialState = {
	appel: null,
	appels: null,
};


export default function appelReducer(state = initialState, action) {


    switch (action.type) {

		case "SET_APPEL":			return { ...state, appel:		action.payload }

		case "SET_APPELS":{
			const newAppels = action.payload.sort((a,b) => (a.dateAppel > b.dateAppel) ? -1 : ((b.dateAppel > a.dateAppel) ? 1 : 0))
			return { ...state, appels:		newAppels }
		}

		case "UPDATE_APPEL_FROM_INDEX":{
			let newAppels = state.appels.map(ap => (ap.id === action.payload.id ? action.payload : ap))
			newAppels = newAppels.sort((a,b) => (a.dateAppel > b.dateAppel) ? -1 : ((b.dateAppel > a.dateAppel) ? 1 : 0))
			return { ...state, appels:		newAppels }
		}

		case "UPDATE_APPEL":{
			return { ...state, appel:		 action.payload }
		}





		case "CREATE_APPEL":{
			let newAppels = state.appels.concat(action.payload)
			newAppels = newAppels.sort((a,b) => (a.dateAppel > b.dateAppel) ? -1 : ((b.dateAppel > a.dateAppel) ? 1 : 0))
			return { ...state, appels:		newAppels }
		}

		case "DELETE_APPEL":{
			let newAppels = state.appels.filter(oldAppel => oldAppel.id !==  action.payload)
			return { ...state, appels: 		newAppels }
		}

		case "UPDATE_APPEL_ELEVE":{
			console.log("UPDATE_APPEL_ELEVE")
			const newAppel = {...state.appel}
			newAppel.appelEleves = state.appel.appelEleves.map(ae => (ae.id === action.payload.id ? action.payload : ae))
			return { ...state, appel: 		newAppel }
		}



        default:					return state
    }



};
