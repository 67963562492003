// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from 'react';
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { useState } from "react";
import {  useSelector,   useDispatch  } from "react-redux";
import Button from 'react-bootstrap/Button';

import { FaExclamationTriangle } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from 'date-fns/locale/fr';


// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import { apiAppelCreate, apiAppelEdit, apiAppelDelete} from '../../api/cmzApi'


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading from '../_nav/Loading'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import './formAppel.scss'


// █████████████████████████████████████████████████████████████████████████████



export default function FormAppel({appel, setAppel, etabId, fromAppelIndex=false}) {


	//const app = useSelector((state) => state.app)
  	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [deleteErrorMessage, setDeleteErrorMessage] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)


	//const today= Date.now()
	//console.log("today", today.format("yyyy"))

	function handleSubmitEdit(){
		//alert("handleSubmitEdit")
		setErrorMessage(null)

		if(!formIsValide()){
			return null
		}

		setIsLoading(true)

		apiAppelEdit(appel)
			.then(
				(result) => {
					//console.log(result)
					if(result.appel){
						if(fromAppelIndex){
							dispatch({type:"UPDATE_APPEL_FROM_INDEX", payload: result.appel})
						}
						else{
							dispatch({type:"UPDATE_APPEL", payload: result.appel})
						}

					}
					closeForm()
					setIsLoading(false)
				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)

	}

	function handleSubmitCreate(){

		setErrorMessage(null)

		if(!formIsValide()){
			return null
		}

		setIsLoading(true)

		apiAppelCreate(appel)
			.then(
				(result) => {
					//	console.log(result)

					if(result.apixError && result.apixError === 'E0012001 - AppelAlreadyExists'){
						setErrorMessage('Un appel a déjà été crée pour cette date. Actualisez la page !')
					}
					else if(result.appel){
						dispatch({type:"CREATE_APPEL", payload: result.appel})
						closeForm()
					}
					setIsLoading(false)
				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)

	}

	const formIsValide=()=>{
		if(!Moment(appel.dateAppel).isValid()){
			setErrorMessage("Date Invalide! Format: AAAA/MM/JJ")
			return false
		}
		if(!appel.prio6 && !appel.prio5 && !appel.prio4 && !appel.prio3){
			setErrorMessage("Vous n'avez pas choisi les classes prios. S'il n'y en a pas, cochez toutes les classes.")
			return false
		}
		return true
	}

	function handleDelete(){

		if ( ! window.confirm('Voulez-vous vraiment supprimer cet appel ?')){
			return
		}
		setIsLoading(true)

		apiAppelDelete(appel.id)
			.then(
				(result) => {
					//console.log(result)

					if (result.REPONSE && result.REPONSE==="OK"){
						dispatch({type:"DELETE_APPEL", payload: appel.id})
						closeForm()
					}
					else{
						//console.log(result)
						alert('error')
						result.detail && setDeleteErrorMessage(result.detail)
					}

					setIsLoading(false)

				},
				(error) => {
					setIsLoading(false)
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)

	}


	function closeForm(){
		setAppel(null)
	}





	//console.log(appel.dateAppel)

    return (

		<div className="zxModalContainer"  >
			<div className="zxModalOpaqueBackground"></div>
			<form className="row col-12 zxModal formAppel" >

				<div className="row col-12 formHeader mb-3"	>
					<h3>
					{appel.id ?
						<>Modifier l'appel : <b>{appel.id}</b></>
						:
						<>Ajouter un appel</>
					}
					{appel.id !== null &&
							<Button
								className="btn btn-danger btn-xs float-end"
								title="Supprimer cet periode"
								onClick={()=>handleDelete()}
							>
								Supprimer
							</Button>
					}
					</h3>
				</div>

				<div className="row col-12 deleteUserErrorMessage">
					{deleteErrorMessage}
				</div>


				<div className="row col-12 fields">


					<div className="form-group mb-4">
						<label for="date" >Date</label>

						<div className="row col-12">
							<div className="col-sm-5 col-12">
								<DatePicker
									selected={Date.parse(appel.dateAppel)}
			      					dateFormat="dd/MM/yyyy"
									onChange={(date) => setAppel({...appel, dateAppel :Moment(date).format("YYYY-MM-DD")})}
			      					locale={fr}
								/>
							</div>
							<div className="col-sm-7 col-12">
								&nbsp;&nbsp;{Moment(appel.dateAppel).format("dddd DD MMM")}
								{ Moment(appel.dateAppel).format("dddd DD/MM/YYYY")  ===  Moment().format("dddd DD/MM/YYYY") &&
									" (Aujourd'hui)"
								}
							</div>
								{ Moment(appel.dateAppel).format("dddd DD/MM/YYYY")  !==  Moment().format("dddd DD/MM/YYYY") &&

									<div className="col-12 text-danger mt-2">
										<b><FaExclamationTriangle /> Ce n'est pas la date d'aujourd'hui !</b>
									</div>
								}

						</div>
						{/*
						<input
							value={appel.dateAppel}
							name="date"
							autoFocus
							onChange={(event)=>setAppel({...appel, dateAppel :event.target.value})}
							className="form-control"
						/>
						*/}
						{ ! Moment(appel.date).isValid() &&
							<small class="text-danger">Date invalide !<br />Format : AAAA/MM/JJ</small>
						}
					</div>





					<div className="form-group mb-4">
						<label for="commentaire" className="col-12">Classes Prios:</label>
							<div class="form-check form-check-inline">
							  	<input class="form-check-input" type="checkbox" id="prio6" checked={appel.prio6} onChange={(event)=>setAppel({...appel, prio6: !appel.prio6})} />
							  	<label class="form-check-label" for="prio6">
							    	6<sup>e</sup>
							  	</label>
							</div>
							<div class="form-check form-check-inline">
							  	<input class="form-check-input" type="checkbox" id="prio5" checked={appel.prio5} onChange={(event)=>setAppel({...appel, prio5: !appel.prio5})} />
							  	<label class="form-check-label" for="prio5">
							    	5<sup>e</sup>
							  	</label>
							</div>
							<div class="form-check form-check-inline">
							  	<input class="form-check-input" type="checkbox" id="prio4" checked={appel.prio4} onChange={(event)=>setAppel({...appel, prio4: !appel.prio4})} />
							  	<label class="form-check-label" for="prio4">
							    	4<sup>e</sup>
							  	</label>
							</div>
							<div class="form-check form-check-inline">
							  	<input class="form-check-input" type="checkbox" id="prio3" checked={appel.prio3} onChange={(event)=>setAppel({...appel, prio3: !appel.prio3})} />
							  	<label class="form-check-label" for="prio3">
							    	3<sup>e</sup>
							  	</label>
							</div>
							<div className="formInfo mt-1 small text-primary">
								S'il n'y a pas de classe Prio, cochez toutes les classes.
							</div>
					</div>



					<div className="form-group mb-4">
						<label for="commentaire" >Commentaire:</label>
						<div><small>(carte qui ne marche pas, élève pas sur la liste...)</small></div>
						<input
							value={appel.commentaire}
							name="commentaire"
							onChange={(event)=>setAppel({...appel, commentaire :event.target.value})}
							className="form-control"
						/>
					</div>


				</div>


				{errorMessage &&
					<div className="row col-12 formError">
						{errorMessage}
				</div>
				}






				{ isLoading ? (<Loading />) : (
					<div className="col-12 mt-3 zone-boutons">




						{appel.id !== null ? (
							<Button
								className="btn btn-success col-4"
								onClick={()=>handleSubmitEdit()}
								tabIndex="0"
							>
								Modifier
							</Button>
						): (
							<Button
								className="btn btn-success col-4"
								onClick={()=>handleSubmitCreate()}
								tabIndex="0"
							>
								Ajouter
							</Button>
						)}

						<Button
							className="btn-secondary col-4 offset-2"
							onClick={()=>closeForm()}
							tabIndex="0"
							>
							Annuler
						</Button>
					</div>
				)}


			</form>


		</div>
    );
  }
