import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './reducers'
import App from './components/App';


const store = createStore(reducer)

ReactDOM.render(
	<Provider store={store}>
	  	<React.StrictMode>
			<App />
	  	</React.StrictMode>
	</Provider>,
	document.getElementById('root'),
)
