
// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import { useState } from 'react'
import { useSelector,  useDispatch  } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaExclamationTriangle } from 'react-icons/fa';
import { apiAppelEleveEdit } from '../../../api/cmzApi'


// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import TableResume		from './TableResume'
import TableFaireAppel	from './TableFaireAppel'
import TableMailExcel	from './TableMailExcel'
import AppelModesButtons	from '../../appel/AppelModesButtons'
import Plan	from '../../plan/Plan'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■



// █████████████████████████████████████████████████████████████████████████████




export default function TableAppelContainer({appel, searchedText=""}) {

  	const dispatch = useDispatch();
	const [mode, setMode] = useState("RESUME")	 //  "RESUME", "APPEL_1", "APPEL_2", "MAIL", "MAIL_EXCEL"
	const dayOfWeek = Moment(appel.dateAppel).format("dddd")

	//const appelWithSortedAE = appel.appelEleves.sort((a,b) => (a.eleve.nom > b.eleve.nom) ? 1 : ((b.eleve.nom > a.eleve.nom) ? -1 : 0))

	appel.appelEleves.sort((a,b) => (a.eleve.nom > b.eleve.nom) ? 1 : ((b.eleve.nom > a.eleve.nom) ? -1 : 0))

	appel.appelEleves.map((ae, a) => {
		let serviceTheorique = 2
		let prioToday = null

		switch(dayOfWeek){
			case "lundi":
				prioToday= ae.eleve.prioLundi
				break
			case "mardi":
				prioToday= ae.eleve.prioMardi
				break
			case "mercredi":
				prioToday= ae.eleve.prioMercredi
				break
			case "jeudi":
				prioToday= ae.eleve.prioJeudi
				break
			case "vendredi":
				prioToday= ae.eleve.prioVendredi
				break
			default:
				console.log("DEFAUT SWICH CASE - pb jours !!!!")
		}

		if (prioToday !== null && prioToday !== ""){
			serviceTheorique=1
		}

		const niveau = ae.eleve.classe.charAt(0)
		if(
			(niveau==="6" && appel.prio6) ||
			(niveau==="5" && appel.prio5) ||
			(niveau==="4" && appel.prio4) ||
			(niveau==="3" && appel.prio3) ){
				serviceTheorique=1
		}

		ae.prioToday = prioToday
		ae.serviceTheorique = serviceTheorique
		return null
	})

	function updateAe(ae){
		console.log(ae)
		ae.placeIsLoading = true
		apiAppelEleveEdit(ae)
			.then(
				(result) => {
					console.log(result)
					if(result.appelEleve){
						dispatch({type:"UPDATE_APPEL_ELEVE", payload: result.appelEleve})
					}
				},
				(error) => {
			   		// TODO
					console.log("error")
					console.log(error)
				}
			)
	}



	return(
		<div className="tableAppelContainer">
			<AppelModesButtons currentMode={mode} setMode={setMode}/>

			{ Moment(appel.dateAppel).format("dddd DD/MM/YYYY")  !==  Moment().format("dddd DD/MM/YYYY") &&
				<h2 className="text-danger text-center mb-5">
					<FaExclamationTriangle /> Ce n'est pas l'appel d'aujourd'hui <FaExclamationTriangle />
				</h2>
			}


			{ mode==="RESUME" &&  <TableResume /> }

			{ (mode==="APPEL_1" || mode==="APPEL_2") &&
				<>
					<div className="legendes">
						{mode==="APPEL_1" ?
							<>
								<b>En blanc</b> : Elèves qui doivent passer au 1er service.<br />
								{/*
								<b>Vert clair</b> : doivent passer au second service<br />
								<b>Gris</b> : élèves inactifs<br />
								*/}
							</>
						:
							<>
								<b>Blanc</b> : Elèves restants.<br />
								<b>Bleu</b> : passés au 1er service<br />
								{/*<b>Gris</b> : élèves inactifs<br />*/}
							</>
						}
					</div>


					<TableFaireAppel
						appel={appel}
						currentService={mode==="APPEL_1" ? 1 : 2}
						updateAe={updateAe}
					/>

					<div className="row col-12 mb-5">
						<div className=" col-lg-6 col-12">
							<RenderResumeSansCarte service={mode==="APPEL_1" ? 1 : 2} />
						</div>
						<div className=" col-lg-6 col-12">
							<RenderResumeManquants service={mode==="APPEL_1" ? 1 : 2} />
						</div>
					</div>
				</>
			}



			{ mode==="PLAN" &&
				<>
					<Plan
						appel={appel}
						currentService={mode==="APPEL_1" ? 1 : 2}
						updateAe={updateAe}
					/>
				</>
			}


			{ mode==="MAIL_EXCEL" &&
				<>
					<TableMailExcel />
				</>
			}

		</div>

	)



	function RenderResumeSansCarte({service}){
		const appelElevesSansCarte = appel.appelEleves.filter(ae => (ae.service === service && ae.sansCarte))
		return(
			<div className=" col-12 zoneResumeSansCarte">
				<h5>{appelElevesSansCarte.length} élèves sans carte au service {service}:</h5>
				<ul>
					{appelElevesSansCarte.map((ae, a) => {
						return (
							<li key={"sc"+a}>
								<b>{ae.eleve.nom}</b> {ae.eleve.prenom} ({ae.eleve.classe})
							</li>
						)
					})}
				</ul>
			</div>
		)
	}


	function RenderResumeManquants({service}){
		const appelElevesManquants = appel.appelEleves.filter(ae => (ae.serviceTheorique === service && !ae.service && ae.eleve.actif  && ae.eleve.classe!=="AED" ))



		return(
			<div className=" col-12 zoneResumeManquants">
				<h5>{appelElevesManquants.length} élèves manquants au service {service}:</h5>
				<ul>
					{appelElevesManquants.map((ae, a) => {
						return (
							<li key={"miss"+a}>
								<b>{ae.eleve.nom}</b> {ae.eleve.prenom} ({ae.eleve.classe})
								{ae.prioToday&&
									<span className="badge bg-primary ml-2">{ae.prioToday}</span>
								}
								{ae.eleve.regime !=="DP" &&
									<div className="badge bg-danger">{ae.eleve.regime}</div>
								}
								{/*
								{!ae.eleve.actif&&
									<span className="badge bg-secondary">Inactif</span>
								}
								*/}
							</li>
						)
					})}
				</ul>
			</div>
		)
	}




}
