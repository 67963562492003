//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import Loading 		from '../_nav/Loading'
//■■■■■■■■■■■■■■■■■■■■ CSS
import "./statistiques.scss"
//██████████████████████████████████████████████████████████████████████████████



export default function Statistiques({match, globalFetch}) {

	const ruser = useSelector((state) => state.rUser)
	const rEleve = useSelector((state) => state.rEleve)

	const [nbreSansCarte, setNbreSansCarte]=useState(10)
	const [nbreAbsences, setNbreAbsences]=useState(10)

    useEffect(() => {
        if (  rEleve.eleves===null    ){
			globalFetch({
				fetchUser: (ruser.user ? false : true),
				fetchAppels: false,
				appelId: null,
				elevesFromEtabId: 10,
			})
    	}
    }, [])



    return (
        <div className="app-page row col-12 pageDpListe">




            <div className="page-header row col-12">
                <div className="page-title col-12">
					Statistiques
				</div>
            </div>

            <div className="page-content row col-12">



				{!rEleve.eleves ?
					<Loading />
				:
					<div className="row col-12">
						<p className="text-danger">
							Les appels cantine antérieurs au 20 septembre 2021 ne sont pas pris en compte dans les calculs suivants.<br />
							Il peut aussi y avoir des chiffres faussés pour les nouveaux élèves qui n'étaient pas sur nos listes.
						</p>

						<h4>Elèves externes sur pronote qui mangent à la cantine:</h4>
						<ul>
							{rEleve.eleves.filter(e=> e.regime!=="DP" && e.actif).map( eleve =>
								<li key={eleve.id}>
									<RenderNomPrenomClasse eleve={eleve} />
									<b>{eleve.appelEleves.filter(ae =>ae.service!==null).length}</b> repas
									<RenderCommentaire eleve={eleve} />
								</li>
							)}
						</ul>

						<h4>
							Elèves avec au moins
							<Button
								className="btn-info btn-sm m-2"
								onClick={() => setNbreSansCarte(nbreSansCarte > 1 ? nbreSansCarte-1 : 1)}
								title="cccccccccc"
							>
								-
							</Button>
							{nbreSansCarte}
							<Button
								className="btn-info btn-sm m-2"
								onClick={() => setNbreSansCarte(nbreSansCarte+1)}
								title="cccccccccc"
							>
								+
							</Button>
							repas sans carte depuis le 20/09/2021:
						</h4>
						<ul>
							{rEleve.eleves.filter(e=> e.appelEleves.filter(e =>e.sansCarte).length >= nbreSansCarte).map( eleve =>
								<li key={eleve.id}>
									<RenderNomPrenomClasse eleve={eleve} />
									<b>{ eleve.appelEleves.filter(e =>e.sansCarte).length}</b> repas sans carte
									sur <b>{eleve.appelEleves.filter(e =>e.service!==null).length}</b> repas.
									<RenderCommentaire eleve={eleve} />
								</li>
							)}
						</ul>

						<h4>
							Elèves DP avec au moins
							<Button
								className="btn-info btn-sm m-2"
								onClick={() => setNbreAbsences(nbreAbsences > 1 ? nbreAbsences-1 : 1)}
								title="cccccccccc"
							>
								-
							</Button>
							{nbreAbsences}
							<Button
								className="btn-info btn-sm m-2"
								onClick={() => setNbreAbsences(nbreAbsences+1)}
								title="cccccccccc"
							>
								+
							</Button>
							absences depuis le 20/09/2021:
						</h4>
						<ul>
							{rEleve.eleves.filter(e=>  e.actif && e.regime==="DP" && e.classe !=="AED" && e.appelEleves.filter(ae => ae.service===null).length >= nbreAbsences).map( eleve =>
								<li key={eleve.id}>
									<RenderNomPrenomClasse eleve={eleve} />
									<b>{ eleve.appelEleves.filter(e =>e.service===null).length}</b> absences
									sur <b>{eleve.appelEleves.length}</b> appels.
									<RenderCommentaire eleve={eleve} />
								</li>
							)}
						</ul>



					</div>

				}

			</div>

 		</div>

    )



	function RenderNomPrenomClasse({eleve}){
		return(
			<NavLink  className="nomPrenomClasse" to={"/eleve/"+eleve.id}	>
				<b>{eleve.nom}</b> {eleve.prenom} ({eleve.classe})
			</NavLink>
		)
	}


	function RenderCommentaire({eleve}){
		return(
			<>{eleve.commentaire && <i>&nbsp;&nbsp;&nbsp;&nbsp;({eleve.commentaire})</i>}</>
		)
	}



}
