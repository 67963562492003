// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';


// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 		from '../_nav/Loading'
import EleveTr 		from './EleveTr'
//////////////////import FormEleve from './FormEleve'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./dp.scss"


// █████████████████████████████████████████████████████████████████████████████
export default function ElevesIndex({eleves, setSelectedEleve, showFoyer, showAppels, showInactif,  showAed,  showDp,  showExterne}) {


	eleves.sort((a,b) => (a.nom.toLowerCase() > b.nom.toLowerCase()) ? 1 : ((b.nom.toLowerCase() > a.nom.toLowerCase()) ? -1 : 0))


	let nbreColonnes = 7 + (showFoyer ? 5 : 0) + (showAppels ? 3 : 0)

//	console.log(app)
    return (
        <table className="tableEleves zxTable">

			<thead>


				<tr className="trCommentaire">
					<th colSpan={nbreColonnes}>
						Liste des élèves & AED inscrits à la Cantine
					</th>
				</tr>

				<tr className="trCommentaire">
					<th colSpan="7">.</th>
					{/*<th colSpan="4">Jours Prio</th>
					<th colSpan="2"></th>*/}
					{showAppels &&
						<th colSpan="3">Appels</th>
					}
					{showFoyer &&
						<th colSpan="5">Foyer</th>
					}
				</tr>


				<tr className="zxTrColumnsHeader">

   					<th></th>
   					<th>Elève</th>
   					<th className="text-center">Classe</th>
   					<th className="text-center">Régime</th>

					<th>Jours prios</th>
					{/*
				   <th>Lundi</th>
				   <th>Mardi</th>
				   <th>Jeudi</th>
				   <th>Vendredi</th>
				   */}

				   <th>carte</th>
				   <th>Commentaire</th>

				   {showAppels &&
					   <>
						   <th>Appels</th>
						   <th>Absences</th>
						   <th>SansCarte</th>
						</>
					}

				   {showFoyer &&
					   <>
						   <th>Inscrit</th>
						   <th>Commentaire</th>
						   <th>Paiement</th>
						   <th>Foyer.1</th>
						   <th>Foyer.2</th>
						</>
					}


				</tr>
			</thead>

			<tbody>

				{ eleves  && eleves.map((eleve, e) => {
					if (!showInactif && !eleve.actif){
						return null
					}
					if (!showAed && eleve.classe==="AED"){
						return null
					}
					if (!showDp && eleve.classe!=="AED" && eleve.regime==="DP"){
						return null
					}
					if (!showExterne && eleve.classe!=="AED" && eleve.regime==="EXT"){
						return null
					}

					return (
						<EleveTr
							key={'eleve-'+e}
							//setSelectedAppel={setSelectedAppel}
							eleve={eleve}
							setSelectedEleve={setSelectedEleve}
							showFoyer={showFoyer}
							showAppels={showAppels}
							/>
					)
				}) }
				{/*
				{ appels.map((appel, a) => {
					return	<AppelTr
							key={'appel-'+a}
							setSelectedAppel={setSelectedAppel}
							appel={appel}
					 	/>
				})}
				*/}
			</tbody>
			{/*
			<tfoot>
				<tr>
				  <td colSpan="5">xxxxxxxxxxxxxxxxxxxxxxx</td>
				</tr>
			</tfoot>
			*/}


		</table>

    )





}
