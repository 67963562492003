// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
//import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

import { diffMoments } from '../../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./appelsIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function AppelTr({appel, setSelectedAppel}) {

	/*
	const sortedAes = appel.appelEleves.sort((a,b) => (a.eleve.nom > b.eleve.nom) ? 1 : ((b.eleve.nom > a.eleve.nom) ? -1 : 0))
	const sortedAppelEleves = sortedAes.filter(ae => (ae.eleve.classe !=="AED"))
	const sortedAppelAeds = sortedAes.filter(ae => (ae.eleve.classe ==="AED"))
	*/
	let momentDate = Moment(appel.dateAppel)
	let diffDays = Moment().diff(momentDate, 'days')

	let numSemaine = parseInt(momentDate.format("W"))


	function handleEditAppelClick(appel){
		appel.date = Moment(appel.dateAppel).format("YYYY-MM-DD")
		setSelectedAppel(appel)
	}


	let trClassName = (diffDays===0 ? " tr-today " : "")

	trClassName += (numSemaine%2 ===0) ? " semaine-paire " : " semaine-impaire "

    return (
		<tr className={trClassName}>
		   <td className="text-end">
				<NavLink  className="" to={"/appel/"+appel.id}	>
					{momentDate.format("dddd DD MMMM YYYY")}
				</NavLink>
		   </td>
		   <td className={"td-humanDate " + (diffDays===0 && " today") }>
				{diffMoments(momentDate)}
		   </td>
		   <td className="text-center">
		   		{appel.prio6 && appel.prio5 && appel.prio4 && appel.prio3
					?
						'Toutes'
				 	:
		   				<>
							{appel.prio6 && <>6<sup>e</sup> </>}
							{appel.prio5 && <>5<sup>e</sup> </>}
							{appel.prio4 && <>4<sup>e</sup> </>}
							{appel.prio3 && <>3<sup>e</sup> </>}
						</>
				}
		   </td>
		   <td>
		   	{appel.commentaire}
		   </td>
		   {/*
		   <td>terminée</td>
		   <td>mail</td>
		   */}
   		   <td
   				className="btn-warning td-edit"
   				title="Modifier l'appel"
   				onClick={()=>handleEditAppelClick(appel)}
   			>
   				<FaEdit />
   			</td>
		</tr>
    )


}
