// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
import { useState } from 'react'
import {useEffect}  from "react";
import { useSelector  /*  ,  useDispatch*/  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';
import { NavLink } from "react-router-dom";
// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 		from '../_nav/Loading'
import TableEleves 		from './TableEleves'
import FormEleve 		from './FormEleve'
// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
//import "./dp.scss"
// █████████████████████████████████████████████████████████████████████████████

export default function ElevesIndex({match, globalFetch}) {

	const ruser = useSelector((state) => state.rUser)
	const rEleve = useSelector((state) => state.rEleve)


	const [selectedEleve, setSelectedEleve] = useState(null)
	const [showAppels, setShowAppels] = useState(true)
	const [showFoyer, setShowFoyer] = useState(false)
	const [showInactif, setShowInactif] = useState(false)
	const [showAed, setShowAed] = useState(false)
	const [showDp, setShowDp] = useState(true)
	const [showExterne, setShowExterne] = useState(true)

    useEffect(() => {
        if (  rEleve.eleves===null    ){
			globalFetch({
				fetchUser: (ruser.user ? false : true),
				fetchAppels: false,
				appelId: null,
				elevesFromEtabId: 10,
			})
    	}
    }, [])


	function handleAddEleveClick(){
		const newEleve={
			id:null,
			nom:"",
			prenom:"",
			classe:"",
			regime:"",
			prioLundi:"",
			prioMardi:"",
			prioMercredi:"",
			prioJeudi:"",
			prioVendredi:"",
			actif: true,
			commentaire: "",
			numeroCarte: "",
		}
		setSelectedEleve(newEleve)
	}


	//console.log("eleves",rEleve.eleves)


    return (
        <div className="app-page row col-12 pageDpListe">

			{selectedEleve !== null  &&
				<FormEleve
					eleve={selectedEleve}
					setEleve={setSelectedEleve}
					etabId={10}
				/>
			}


            <div className="page-header row col-12">
                <div className="page-title col-12">
					Liste des élèves (et AED) inscrits à la demi-pension.
				</div>
            </div>

            <div className="page-content row col-12">



				<div className="row col-12">
					{/*
					<div className="row col-6 legendes mb-5">
						<h5>Légende:</h5>
						<div className="col-4 legende inactif">
							Elèves Inactifs (qui ont quitté le collège)
						</div>
						<div className="col-4 legende externe">
							Elèves Externes
						</div>
					</div>
					*/}
					<div className="col-6 legendes mb-5">
						<Button
							className="btn-primary float-right"
							onClick={() => handleAddEleveClick()}
							title="Ajouter un élève"
						>
							<FaPlus /> Ajouter un élève ou un AED
						</Button>
					</div>
				</div>





				<div className=" col-12 mb-5">
					<ul>
						<li>Les régimes et les groupes (DF, choral, LCE...) de cette liste correspondent à ceux de Pronote.</li>
						<li>Il faut mettre à jour cette liste s'il y a des changements de régime ou de groupe. <small>(Créez un nouvel élève ou cliquez sur &nbsp;<FaEdit /> pour le modifier)</small></li>
						<li>
							Il y a des élèves externes <small className="legende-externe">(lignes bleues)</small>. <small>Ce sont des élèves qui mangent occasionnellement ou dont le régime n'a pas encore été modifié sur pronote. (voir avec la gest. ou la sec.)</small>
						</li>
						<li>
							Si un élève ne mangera plus du tout à la cantine <small className="legende-inactif">(lignes grises)</small>, <b>il ne faut pas le supprimer</b>, mais le modifier et décocher la case "actif". <small>Il n'apparaitra plus dans les listes d'appel.</small>
						</li>
					</ul>
				</div>


				<div className="col-12 filtres mb-5">
					<Button
						className={showAppels ? "btn-info" : "btn-secondary" }
						onClick={() => setShowAppels(!showAppels)}
						title="cccccccccc"
					>
						colonnes Appels
					</Button>
					&nbsp;
					<Button
						className={showFoyer ? "btn-info" : "btn-secondary" }
						onClick={() => setShowFoyer(!showFoyer)}
						title="cccccccccc"
					>
						colonnes Foyer
					</Button>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<Button
						className={ (showInactif ? "btn-info" : "btn-secondary")  }
						onClick={() => setShowInactif(!showInactif)}
						title="cccccccccc"
					>
						élèves inactifs <small>(ne mangent plus à la cantine)</small>
					</Button>
					&nbsp;
					<Button
						className={ (showAed ? "btn-info" : "btn-secondary")  }
						onClick={() => setShowAed(!showAed)}
						title="cccccccccc"
					>
						AED
					</Button>
					&nbsp;
					<Button
						className={ (showExterne ? "btn-info" : "btn-secondary")  }
						onClick={() => setShowExterne(!showExterne)}
						title="cccccccccc"
					>
						Externe
					</Button>
					&nbsp;
					<Button
						className={ (showDp ? "btn-info" : "btn-secondary")  }
						onClick={() => setShowDp(!showDp)}
						title="cccccccccc"
					>
						DP
					</Button>
				</div>

				{!rEleve.eleves ?
					<Loading />
				:


					<TableEleves
						eleves={rEleve.eleves}
						setSelectedEleve={setSelectedEleve}
						showFoyer={showFoyer}
						showAppels={showAppels}
						showInactif={showInactif}
						showAed={showAed}
						showDp={showDp}
						showExterne={showExterne}
					/>

				}

			</div>

 		</div>

    )

}
