// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import { BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
//import { useEffect } from 'react';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
import {apiGlobalFetch} from '../api/cmzApi'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import NavHeader 	from './_nav/NavHeader'
import Login 		from './login/Login'
import AppelsIndex 	from './appelsIndex/AppelsIndex'
import ElevesIndex 	from './elevesIndex/ElevesIndex'
//import Loading 	from './_nav/Loading'
//import Home 		from './home/Home'
import AppelShow 	from './_pages/AppelShow'
import Foyer 		from './_pages/Foyer'
import Statistiques from './_pages/Statistiques'
import EleveShow 	from './_pages/EleveShow'
import About 		from './_pages/About'

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.scss';


// █████████████████████████████████████████████████████████████████████████████

export default function App() {

	let rUser = useSelector((state) => state.rUser)
	const dispatch = useDispatch();
	const [appIsFetching, setAppIsFetching] = useState(false)
	const [appFetchingError, setAppFetchingError] = useState(null)

	//localStorage.removeItem("userToken")

	function globalFetch(options){
	  	//console.log('██ globalFetch ██');
		if(!rUser.user && !localStorage.getItem("userToken")){
			console.log("ANONYMOUS:")
			dispatch({ type: "SET_USER_STATUS", payload: "ANONYMOUS" })
			setAppIsFetching(false)
			return
		}
		console.log('██ globalFetch - exec ██');
		setAppIsFetching(true)
		//console.log(localStorage.getItem("userToken"))
		//console.log(options)

		apiGlobalFetch(options)
		.then(
			(result) => {
				console.log(result)

				if(result.apixError){
					if(result.apixError ===  "USER_NOT_FOUND"){
						console.log("apixError : USER_NOT_FOUND : localStorage.removeItem(userToken)+++")
						localStorage.removeItem("userToken")
						dispatch({ type: "SET_USER_STATUS", payload: "ANONYMOUS" })
					}
				}

				if(result.user && result.user !== 'notFetched'){
					dispatch({ type: "SET_USER", payload: result.user })
				}

				if(result.appels && result.appels !== 'notFetched'){
					dispatch({ type: "SET_APPELS", payload: result.appels })
				}

				if(result.appel && result.appel !== 'notFetched'){
					dispatch({ type: "SET_APPEL", payload: result.appel })
				}

				if(result.eleves && result.eleves !== 'notFetched'){
					dispatch({ type: "SET_ELEVES", payload: result.eleves })
				}
				if(result.eleve && result.eleve !== 'notFetched'){
					dispatch({ type: "SET_ELEVE", payload: result.eleve })
				}

				setAppIsFetching(false)


	   			//console.groupEnd();
			},
			(error) => {
				setAppIsFetching(false)
				setAppFetchingError("apiMesEtablissements-unknown error-check log")
				console.log(error)
				console.log("error")

	   			//console.groupEnd();
			}
		)

	}






	// ██████████████████   return   ██████████████████



  	return (
		<>
		{(rUser.userStatus==="ANONYMOUS" || rUser.userStatus==="LOGGING") ?
			<Login />
		:
	    	<Router>
	      		<div className="App" id="container-global">
					<NavHeader />
					<RenderSwitchRoute />
		      	</div>
		    </Router>
		}
	{/*
		<div className="debug">
			rUser.userStatus: <b>{ rUser.userStatus }</b><br />
			localStorage.getItem("userToken"): <b>{ localStorage.getItem("userToken") }</b><br />
		</div>
*/}
		</>
  	)


	function RenderSwitchRoute(){
		return(
	        <Switch>
				{/*
				<Route exact path="/" render={(props) => ( <Home
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>
				*/}


				<Route exact path="/appel/:id" render={(props) => ( <AppelShow
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>

				<Route exact path="/eleves" render={(props) => ( <ElevesIndex
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>

				<Route exact path="/statistiques" render={(props) => ( <Statistiques
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>


				<Route exact path="/about" render={(props) => ( <About
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>


				<Route exact path="/eleve/:id/" render={(props) => ( <EleveShow
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>



				<Route exact path="/foyer" render={(props) => ( <Foyer
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>




				<Route path="/" render={(props) => ( <AppelsIndex
							{...props}
							globalFetch={globalFetch}
							appIsFetching={appIsFetching}
							appFetchingError={appFetchingError}
						/>	)}
				/>

	        </Switch>
		)
	}



}
