const useLocalBdd = false	/// attention, il y a un fetch dans login

//const apiToken = localStorage.getItem("userToken") ne pas le mettre ici en constante car le token peut changer
const apiBaseUrl = useLocalBdd ? "http://cmz.postages.zedixi.local/" : "https://cmz.api.zedixi.com/"


//██████████████████████████████████	NEW GLOBAL FETCHING		████████████████


export function apiGlobalFetch(options){
	const url = apiBaseUrl + "apix/dp/global_fetch"

	//console.log(options)


	let fetchUser 		= options.fetchUser 	? options.fetchUser		: false
	let fetchAppels		= options.fetchAppels 	? options.fetchAppels	: false
	let fetchEleves		= options.fetchEleves 	? options.fetchEleves	: false
	let appelId  		= options.appelId		? options.appelId		: null
	let eleveId  		= options.eleveId		? options.eleveId		: null
	let elevesFromEtabId  = options.elevesFromEtabId	? options.elevesFromEtabId	: null
	/*
	let fetchMesEtabs		= options.fetchMesEtabs	? options.fetchMesEtabs	: false
	let fetchEtabFromWorkspace	= options.fetchEtabFromWorkspace	? options.fetchEtabFromWorkspace	: false
	let etabId				= options.etabId		? options.etabId	: null
	let workspaceId			= options.workspaceId	? options.workspaceId	: null
	let appelsDpFromEtabId  = options.appelsDpFromEtabId	? options.appelsDpFromEtabId	: null
	*/


	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},

	    body: JSON.stringify({
			fetchUser: fetchUser,
			fetchAppels: fetchAppels,
			fetchEleves: fetchEleves,
			appelId: appelId,
			eleveId: eleveId,
			elevesFromEtabId: elevesFromEtabId,
			/*
			fetchMesEtabs: fetchMesEtabs,
			fetchEtabFromWorkspace: fetchEtabFromWorkspace,
			etabId: etabId,
			workspaceId: workspaceId,
			appelsDpFromEtabId: appelsDpFromEtabId,
			*/
		})

	};

	return fetch(url, requestOptions).then(res => res.json())

}


//██████████████████████████████████	Cantine - APPEL	████████████████████████████


export function apiAppelEdit(appel){

	const url = apiBaseUrl + "apix/dp/appel/edit/"+appel.id

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			dateAppel: appel.dateAppel,
			prio6: appel.prio6,
			prio5: appel.prio5,
			prio4: appel.prio4,
			prio3: appel.prio3,
			commentaire: appel.commentaire,
			etabId: appel.etabId,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())

}

export function apiAppelCreate(appel){

	const url = apiBaseUrl + "apix/dp/appel/create"

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			dateAppel: appel.dateAppel,
			prio6: appel.prio6,
			prio5: appel.prio5,
			prio4: appel.prio4,
			prio3: appel.prio3,
			commentaire: appel.commentaire,
			etabId: appel.etabId,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())

}

export function apiAppelDelete(appelId){

	const url = apiBaseUrl + "apix/dp/appel/delete/"+appelId

	const requestOptions = {
	    method: 'DELETE',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		} /*,
	    body: JSON.stringify({
			periodeId: userId,
		})
		*/
	};

	return fetch(url, requestOptions).then(res => res.json())

}




//██████████████████████████████████████████████████████████████████████████████




export function apiAppelEleveEdit(ae){

	const url = apiBaseUrl + "apix/dp/appel_eleve/edit/"+ae.id

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			service: ae.service,
			sansCarte: ae.sansCarte,
			place: ae.place ?  ae.place : null,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())

}

//██████████████████████████████████	ELEVE	████████████████████████████


export function apiEleveEdit(id, nom, prenom, classe, regime, prioLundi, prioMardi, prioMercredi, prioJeudi, prioVendredi, commentaire, actif, numeroCarte){

	const url = apiBaseUrl + "apix/dp/eleve/edit/"+id

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			nom: nom,
			prenom: prenom,
			classe: classe,
			regime: regime,
			prioLundi: prioLundi,
			prioMardi: prioMardi,
			prioMercredi: prioMercredi,
			prioJeudi: prioJeudi,
			prioVendredi: prioVendredi,
			commentaire: commentaire,
			actif: actif,
			numeroCarte: numeroCarte,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())

}

export function apiEleveCreate(etabId, nom, prenom, classe, regime, prioLundi, prioMardi, prioMercredi, prioJeudi, prioVendredi, commentaire, actif, numeroCarte){

	const url = apiBaseUrl + "apix/dp/eleve/create"

	const requestOptions = {
	    method: 'POST',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		},
	    body: JSON.stringify({
			etabId: etabId,
			nom: nom,
			prenom: prenom,
			classe: classe,
			regime: regime,
			prioLundi: prioLundi,
			prioMardi: prioMardi,
			prioMercredi: prioMercredi,
			prioJeudi: prioJeudi,
			prioVendredi: prioVendredi,
			commentaire: commentaire,
			actif: actif,
			numeroCarte: numeroCarte,
		})
	};


	return fetch(url, requestOptions).then(res => res.json())

}

export function apiEleveDelete(eleveId){

	const url = apiBaseUrl + "apix/dp/eleve/delete/"+eleveId

	const requestOptions = {
	    method: 'DELETE',
	    headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem("userToken")
		} /*,
	    body: JSON.stringify({
			periodeId: userId,
		})
		*/
	};

	return fetch(url, requestOptions).then(res => res.json())

}



//██████████████████████████████████████████████████████████████████████████████
