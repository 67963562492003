// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■

import React from "react";
import { useState } from 'react'
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import { useSelector,  useDispatch  } from "react-redux";
import Button from 'react-bootstrap/Button';
import { FaFileDownload } from 'react-icons/fa';
//import ReactExport from "react-export-excel";		// https://www.npmjs.com/package/react-export-excel
import ReactExport from 'react-data-export';		// https://github.com/securedeveloper/react-data-export + yarn add xlsx --save
// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■



// █████████████████████████████████████████████████████████████████████████████



export default function TableMailExcel() {

	let appel = useSelector((state) => state.rAppel.appel)

	let appelEleves = appel.appelEleves.filter(ae => (ae.eleve.regime==="DP" && ae.eleve.actif && ae.eleve.classe !=="AED"))
	appelEleves = appelEleves.concat(appel.appelEleves.filter(ae => ((ae.eleve.regime!=="DP" ||  !ae.eleve.actif ) && ae.eleve.classe !=="AED" && ae.service)))	//	externes
	appelEleves = appelEleves.concat(appel.appelEleves.filter(ae => (ae.eleve.classe ==="AED")))	//aed


	const ExcelFile = ReactExport.ExcelFile;
	const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


	const nbreEleves = appelEleves.length
	let couplesAppelEleves = []

	for (let i = 0; i < nbreEleves/2; i++) {
		let index2= i + Math.ceil(nbreEleves / 2)
		let couple={
			gauche: appelEleves[i],
			droite: (typeof appelEleves[index2] === 'undefined') ? null : appelEleves[index2]
		}
		couplesAppelEleves.push(couple)
	}


	const styleBorder={		top: {style: "thin"},		left: {style: "thin"},		right: {style: "thin"},		bottom: {style: "thin"},	}
	const styleCell ={			border:styleBorder,		font: {sz: "10"	}}
	const styleCellSansCarte ={	border:styleBorder,		font: {	sz: "7"	},		fill: {patternType: "solid", fgColor: {rgb: "FFEBEB"}}	}
	const styleCellPresent ={	border:styleBorder,		font: {	sz: "7"	}	}
	const styleCellAbsent ={	border:styleBorder,		font: {	sz: "7"	},		fill: {patternType: "solid", fgColor: {rgb: "FFEBEB"}}	}
	const styleCellTuto ={		font: {sz: "12", color:{ rgb: "FFFF0000" }		}	}
	const styleCellCommentaire ={		font: {sz: "12", color:{ rgb: "FF0000FF" }		}	}
	//const styleCellDate ={		font: { sz: "12", bold: true		}	}


	const tutoStr=[
		"Avant d'envoyer ce fichier par mail, il faut:",
		"     - Reduire la hauteur des lignes pour que tout rentre sur une page",
		"     - Supprimer ces cellules rouges",
		"     - Enregistrer : [Ctrl + S]",
		"",
		"",
		"",
		"Excel pour les nuls:",
		"",
		"     Vous pouvez faire   [Ctrl+P] puis [Echap]   pour voir apparaître les limites de la page (en pointillés)",
		"     Sinon, vous pouvez aussi faire : \"Affichage\" > \"Avec sauts de page\"",
		"",
		"",
		"     Pour séléctionner toutes les lignes (sauf celles d'en-tête):",
		"         - Cliquez sur le numéro à gauche de la 1ere ligne (2 ou 3)",
		"         - Laissez le bouton de la souris enfoncé",
		"         - Descendez jusqu'en bas du tableau",
		"         - Relachez le bouton de la souris",
		"         - Passez la souris entre 2 cases (avec les numéros des lignes) pour faire apparaître le curseur de redimensionnement",
	]


	const dataSetCouples = [];
	if (appel.commentaire){
		dataSetCouples.push([
			{ style:styleCellCommentaire, 		value: `Commentaire: ${appel.commentaire}`},
		])
	}

	for (let i = 0; i < couplesAppelEleves.length; i++) {
		const ae1 = couplesAppelEleves[i].gauche
		const ae2 = couplesAppelEleves[i].droite

		dataSetCouples.push([
			{ style:styleCell, 		value: `${ae1.eleve.nom} ${ae1.eleve.prenom} (${ae1.eleve.classe}) ${ae1.eleve.regime !== "DP" ? "Externe" : ""}`	 },
			{ style: ae1.service ? styleCellPresent:styleCellAbsent, 		value: ae1.service ? "Present" : "Absent",	 },
			{ style:ae1.sansCarte ? styleCellSansCarte : styleCell , 		value: ae1.sansCarte ? "Sans Carte" : ""	 },

			{ style:styleCell, 		value: ae2 ? `${ae2.eleve.nom} ${ae2.eleve.prenom} (${ae2.eleve.classe}) ${ae2.eleve.regime !== "DP" ? "Externe":""}` : ""	 },
			{ style: ae2 ? ( ae2.service ? styleCellPresent : styleCellAbsent ) : styleCell,  		value: ae2 ? ( ae2.service ? "Present" : "Absent" ) : ""	 },
			{ style:ae2 && ae2.sansCarte ? styleCellSansCarte : styleCell , 	value: ae2 && ae2.sansCarte ? "Sans Carte" : ""	 },

			{ value: ""	 },
			{ style: styleCellTuto, value: i<tutoStr.length ? tutoStr[i] : ""	 },
		])
	}


	const dataset = [
	    {
	        columns: [
	            {title: "Appel Cantine Camus", width: {wpx: 200}},
	            {title: "", width: {wpx: 35}},
	            {title: "", width: {wpx: 45}},
	            {title: Moment(appel.dateAppel).format("dddd DD MMM YYYY"), width: {wpx: 200}},
	            {title: "", width: {wpx: 35}},
	            {title: "", width: {wpx: 45}},
	            {title: "", width: {wpx: 10}},	//vide
	            {title: "", width: {wpx: 300}},	//tuto
	        ],
	        data: dataSetCouples
	    }
	];

	return(
		<>

			<RenderExcelDownload />




			<table className="table table-bordered table-sm table-appel-mail zxTable">


				<RenderTHead />

				<tbody>
					{ couplesAppelEleves.map((couple, c) => {
						return <RenderTrCoupleAppelEleve
							ae1={couple.gauche}
							ae2={couple.droite}
							key={'couple-tr-'+c}
							/>
					})}
				</tbody>

			</table>
		</>
	)


	function RenderTHead(){
		return (
		  	<thead>
				<tr>
				   	<th colSpan="6">
					   	<b>{Moment(appel.dateAppel).format("dddd DD/MM/YYYY")}</b>
				   	</th>
				</tr>

				{appel.commentaire &&
					<tr className="trCommentaire">
					   	<th colSpan="6">
						   	<b>Commentaire: </b>
						   	{appel.commentaire}
					   	</th>
					</tr>
				}

			</thead>
		)
	}



	function RenderTrCoupleAppelEleve({ae1, ae2}){


		return (
			<tr >


				<td className="eleve">
					<b>{ae1.eleve.nom}</b> {ae1.eleve.prenom} ({ae1.eleve.classe})
					{ae1.eleve.regime !== "DP" && <span className="badge bg-warning text-black">Externe</span>}
				</td>
				<td>
					{ae1.service ? "Present" : "Abs" }
				</td>
				<td>
					{ae1.sansCarte && "Sans Carte" }
				</td>
				{/*<td>{ae1.commentaire}</td>*/}




				<td className="eleve">
					{ae2 && <>
						<b>{ae2.eleve.nom}</b> {ae2.eleve.prenom} ({ae2.eleve.classe})
						{ae2.eleve.regime !== "DP" && <span className="badge bg-warning text-black">Externe</span>}
					</>}
				</td>
				<td>
					{ae2 && <>
						{ae2.service ? "Present" : "Abs" }
					</>}
				</td>
				<td>
					{ae2 && <>
						{ae2.sansCarte && "Sans Carte" }
					</>}
				</td>
				{/*<td>{ae2.commentaire}</td>*/}



			</tr>
		)
	}

	function RenderExcelDownload(){
		return(


			<div className="row col-12 mb-5" style={{ maxWidth: "900px", margin:"auto"}}>

				<div className="col-4">
		            <ExcelFile
						element={<Button><FaFileDownload/> Télécharger le fichier excel</Button>}
						filename={"Cantine - Mail - "+Moment(appel.dateAppel).format("YYYY-MM-DD - dddd")}
						className="float-end"
					>
		                <ExcelSheet
							dataSet={dataset}
							name={Moment(appel.dateAppel).format("DD-MM-YYYY - dddd")}
						/>
		            </ExcelFile>
				</div>
				<div className="col-8">
					<ul>
						<li>Téléchargez le fichier excel.<br /><small>Vous pouvez le mettre dans le dossier &nbsp; <b>~~T/viesco/Appels Cantine~~</b></small></li>
						<li>Ouvrez-le et <span style={{color:'red'}}>suivez les indications en rouge</span> <small>(pour faire la mise en page).</small></li>
						<li>Envoyez-le fichier modifié par mail à Lulu et à la gestionnaire.</li>
						{/* <li><small>Si le téléchargement ne marche pas, vous pouvez aussi copier/coller le tableau ci dessous dans excel (pas dans libre office calc!)</small></li> */}
					</ul>
				</div>


			</div>

		)
	}




}
