// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
//import { useState } from "react";
import { useEffect } from "react";
import { useSelector /*  ,  useDispatch*/ } from "react-redux";
import Moment from 'moment';	//Moment is great but very large for a simple use like this. I recommend using date-fns (https://date-fns.org/) (https://github.com/date-fns/date-fns)
import 'moment/locale/fr';
import { NavLink } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { FaPlus, FaEdit } from 'react-icons/fa';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

//import { diffMoments } from '../../../helpers/fonctions'

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import "./eleveIndex.scss"


// █████████████████████████████████████████████████████████████████████████████

export default function EleveTr({eleve, setSelectedEleve, showFoyer, showAppels}) {

	/*
	function handleEditAppelClick(appel){
		appel.date = Moment(appel.dateAppel).format("YYYY-MM-DD")
		setSelectedAppel(appel)
	}


	let trClassName = (diffDays===0 ? " tr-today " : "")

	trClassName += (numSemaine%2 ===0) ? " semaine-paire " : " semaine-impaire "
	*/

	let trClassName = "  "

	if(!eleve.actif){
		trClassName += " tr-inactif "
	}

	if(eleve.regime !== "DP"){
		trClassName += " tr-externe "
	}

	if(eleve.classe==="AED"){
		trClassName += " tr-aed "
	}


    return (
		<tr className={trClassName}>

			<td
				className="btn-warning text-center"
				onClick={() => setSelectedEleve(eleve)}
				title="Modifier cet élève"
			>
				<FaEdit />
		   	</td>

		   	<td >
				<NavLink  className="" to={"/eleve/"+eleve.id}	>
					<b>{eleve.nom}</b> {eleve.prenom}
				</NavLink>
			 </td>

		   	<td className="text-center">{eleve.classe}</td>

		   	<td className="text-center">
		   		{!eleve.actif ? "Inactif"   :  eleve.regime	}
		   	</td>

   		   	<td className="joursPrio">
				{eleve.prioLundi 	&& <span><b>Lundi:</b> {eleve.prioLundi}</span>}
				{eleve.prioMardi 	&& <span><b>Mardi:</b> {eleve.prioMardi}</span>}
				{eleve.prioMercredi && <span><b>Mercredi:</b> {eleve.prioMercredi}</span>}
				{eleve.prioJeudi 	&& <span><b>Jeudi:</b> {eleve.prioJeudi}</span>}
				{eleve.prioVendredi && <span><b>Vendredi:</b> {eleve.prioVendredi}</span>}
   			</td>
			{/*
   		   	<td>{eleve.prioLundi}</td>
   		   	<td>{eleve.prioMardi}</td>
   		   	<td>{eleve.prioJeudi}</td>
   		   	<td>{eleve.prioVendredi}</td>
			*/}


   		   	<td>{eleve.numeroCarte}</td>

   		   	<td>{eleve.commentaire}</td>


		   	{showAppels &&
			   	<>
				   	<td>{eleve.appelEleves.length}</td>
					<td>{eleve.appelEleves.filter(ae =>ae.service===null).length}</td>
					<td>{eleve.appelEleves.filter(ae =>ae.sansCarte).length}</td>
				</>
			}


		   	{showFoyer &&
			   	<>
				   	<td className="text-center">{eleve.foyerInscrit && "Foyer"}</td>
				   	<td className="text-center">{eleve.foyerCommentaire}</td>
				   	<td className="text-center">
						{eleve.foyerPaiementMontant && eleve.foyerPaiementMontant+" €"}
						{eleve.foyerPaiementMoyen && ` [${eleve.foyerPaiementMoyen}]`}
						{eleve.foyerPaiementCommentaire && ` (${eleve.foyerPaiementCommentaire})`}
					</td>
		   		   	<td>{eleve.foyerAttribut1}</td>
					<td>{eleve.foyerAttribut2}</td>
				</>
			}
		</tr>
    )


}
