// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { useEffect } from "react"
import Form from "react-bootstrap/Form"
//import Loading from "../_nav/Loading"
import Button from "react-bootstrap/Button"

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■
//import { apiGetUsersForDebugLogin, apiMesEtablissements } from "../../api/cmzApi"

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■
import Loading 			from '../_nav/Loading'


// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import "./login.scss";


// █████████████████████████████████████████████████████████████████████████████


export default function Login() {


	const dispatch = useDispatch();

	const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    //const [user, setUser] = useState({})


    function validateForm() {
        return username.length > 0 && password.length > 0
    }

    function handleSubmit(event) {
        event.preventDefault()
		fetchLogin()
    }



	async function fetchLogin(){
	  	console.group('fetchLogin');
		setIsLoading(true)
		setError(null)
		const url = "https://cmz.api.zedixi.com/apix/login"
		//const url = "http://cmz.postages.zedixi.local/apix/login"

		const requestOptions = {
		    method: 'POST',
		    headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
			},
		    body: JSON.stringify({
				username: username,
				password: password,
			})
		};

		dispatch({ type: "SET_USER_STATUS", payload: "LOGGING" })
		try{
			const response = await fetch(url, requestOptions)
			const {user, appel, appels, apixError} = await response.json()


			if(apixError){
				console.log("apixError !!!:")
				console.log(apixError)
				setError(apixError)
			}
			else{
				if(user){
					console.log("user:")
					console.log(user)
					dispatch({ type: "LOG_IN", payload: user })
					if(appel){
						console.log("appel:")
						console.log(appel)
						dispatch({ type: "SET_APPEL", payload: appel })
					}
					if(appels){
						console.log("appels:")
						console.log(appels)
						dispatch({ type: "SET_APPELS", payload: appels })
					}

				}
				else{
					console.log("unknown fetchLogin error")
				}
			}


		}
		catch(err){
			console.log("■ ■ ■ catch")
			console.log("fetchLoginError:")
			console.log(err)
		}
		finally{
			setIsLoading(false)
			console.groupEnd();
		}

	}


    return (
        <div className="app-page row col-12">
	        <div className="Login">
	            <Form onSubmit={handleSubmit}>

					<div className="app-title">
						cmzdp.zedixi.com
					</div>

					<img
						alt="cmzDp"
						src="/img/app-logo/app-logo-512.png"
						className="app-logo mb-3"
					/>


	                <Form.Group size="lg" controlId="email">
	                    <Form.Label>Nom d'utilisateur</Form.Label>
	                    <Form.Control
	                        autoFocus
	                        type="username"
	                        value={username}
	                        onChange={(e) => setUsername(e.target.value)}
	                    />
	                </Form.Group>
	                <Form.Group size="lg" controlId="password">
	                    <Form.Label>Mot de passe</Form.Label>
	                    <Form.Control
	                        type="password"
	                        value={password}
							autoComplete="on"
	                        onChange={(e) => setPassword(e.target.value)}
	                    />
	                </Form.Group>
					{isLoading ?
						<Loading message="Connexion"/>
					:
		                <Button
							className="mt-3"
		                    block
		                    size="lg"
		                    type="submit"
		                    disabled={!validateForm()}
		                >
		                    Connexion
		                </Button>
					}


					{error && <div>--{error}--</div>}
	            </Form>
	        </div>

	    </div>
    )
}
