const initialState = {
	userStatus: "?", 		//	"ANONYMOUS", "LOGGING", "FETCHING", "LOGGED"
	user: null,
	//userToken: localStorage.getItem("userToken"),
};


export default function userReducer(state = initialState, action) {


    switch (action.type) {

		case "SET_USER_STATUS":		return { ...state, userStatus: 	action.payload }

		case "SET_USER":{

			return { ...state,
				user: action.payload,
				userStatus: "LOGGED"
			}
		}

		case "LOG_IN":{
			console.log("action.payload.apiToken")
			console.log(action.payload.apiToken)
			localStorage.setItem("userToken", action.payload.apiToken)
			return { ...state,
				userStatus:		"LOGGED",
				user: action.payload,
			}
		}

		case "LOG_OUT":{
			localStorage.removeItem("userToken")
			return { ...state,
				userStatus:		"ANONYMOUS",
				user: null,
			}
		}

        default:					return state

    }



};
