// ■■■■■■■■■■■■■■■■■■■■ LIBS : ■■■■■■■■■■■■■■■■■■■■
import React from "react";
//import { NavLink } from "react-router-dom";
//import { Link } from "react-scroll";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, NavDropdown, Nav /*, Dropdown*/ } from "react-bootstrap";
//import {useEffect}  from "react";
import { useSelector,  useDispatch  } from "react-redux";
import { FaUserGraduate, FaClipboardList, FaTableTennis, FaQuestion} from 'react-icons/fa';
import { IoMdStats} from 'react-icons/io';

// ■■■■■■■■■■■■■■■■■■■■ HELPERS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ COMPONENTS : ■■■■■■■■■■■■■■■■■■■■

// ■■■■■■■■■■■■■■■■■■■■ CSS : ■■■■■■■■■■■■■■■■■■■■
import './navHeader.scss'




// █████████████████████████████████████████████████████████████████████████████

export default function NavHeader() {
	//const app = useSelector((state) => state.app)
	const rUser = useSelector((state) => state.rUser)
	const dispatch = useDispatch();
	//console.log(app.user)

	function btLogoutClick(){
		dispatch({ type: "LOG_OUT" })
	}



	return (
		<Navbar variant="dark" expand="lg" className="app-navbar navbar-expand-sm">
		  	<Container  fluid>

			    <Navbar.Brand >
					<img
				        src="/img/app-logo/app-logo-32.png"
				        alt="CmzDp"
				    />
					<span className="app-title">
						Cmz<b>Dp</b>.zedixi.com
					</span>
	  			</Navbar.Brand>

			    <Navbar.Toggle aria-controls="basic-navbar-nav" />


				<Navbar.Collapse id="basic-navbar-nav">
				    <Nav className="me-auto container-fluid">

						{false &&
 							<h5 style={{color: "white",}}>CmzDp.zedixi.com</h5>
						}

						{/*<LinkContainer exact to="/"	 disabled 	><Nav.Link>Accueil</Nav.Link></LinkContainer>*/}
						<LinkContainer exact to="/"					><Nav.Link><FaClipboardList /> Appels Cantine</Nav.Link></LinkContainer>
						<LinkContainer exact to="/eleves" 			><Nav.Link><FaUserGraduate /> Liste des élèves</Nav.Link></LinkContainer>
						<LinkContainer exact to="/statistiques" 	><Nav.Link><IoMdStats /> Statistiques</Nav.Link></LinkContainer>
						<LinkContainer exact to="/foyer" 			><Nav.Link><FaTableTennis /> Foyer</Nav.Link></LinkContainer>
						<LinkContainer exact to="/about" 			><Nav.Link><FaQuestion /> About</Nav.Link></LinkContainer>

						{/*
						<LinkContainer exact to="/etabs"  disabled  ><Nav.Link>Mes établissements</Nav.Link></LinkContainer>
						<LinkContainer exact to="/fse"	  disabled 	><Nav.Link>FSE</Nav.Link></LinkContainer>

						{true &&
					        <NavDropdown disabled 	 title="Admin" id="basic-nav-dropdown" className="ml-auto" >
					          	<NavDropdown.Item href="#action/3.3"><del>Gestion des utilisateurs</del></NavDropdown.Item>
					          	<NavDropdown.Item href="#action/3.2"><del>Another action</del></NavDropdown.Item>
					          	<NavDropdown.Divider />
					          	<NavDropdown.Item href="#action/3.4"><del>Separated link</del></NavDropdown.Item>
					       	</NavDropdown>
						}
						*/}

						{rUser.userStatus === "LOGGED" &&

					        <NavDropdown title={rUser.user.pseudo} id="user-basic-nav-dropdown" className="ms-auto" >
					          	<NavDropdown.Item onClick={()=>btLogoutClick()}>Déconnexion</NavDropdown.Item>
					          	<NavDropdown.Divider />
					          	<NavDropdown.Item href="#action/3.3"><del>Mon profil</del></NavDropdown.Item>
					       	</NavDropdown>

						}

				    </Nav>
			    </Navbar.Collapse>


		  	</Container>
		</Navbar>
	)

}
