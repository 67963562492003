//██████████████████████████████████████████████████████████████████████████████
//■■■■■■■■■■■■■■■■■■■■ LIBS
import React from "react";
import { useState } from 'react'
import Button from 'react-bootstrap/Button';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components'
//■■■■■■■■■■■■■■■■■■■■ HELPERS
//■■■■■■■■■■■■■■■■■■■■ COMPONENTS
import Place 		from './Place'
import BoutonTable from './BoutonTable'
//■■■■■■■■■■■■■■■■■■■■ STYLES
//import {theme, StyledPage} from '../../styles/theme'
//██████████████████████████████████████████████████████████████████████████████



//import TrFaireAppel from './TrFaireAppel';

export default function Plan({ appel,  updateAe}) {


	const [service, setService] = useState(1)
	const [niveau, setNiveau] = useState(null)
	const [tables, setTables] = useState(["1", "2", "3", "4","5","6","7","8","9","10","11","12","13"])
	const [table, setTable] = useState("1")
	const [place, setPlace] = useState(null)



//	const appelEleves = appel.appelEleves.filter(ae => (ae.eleve.classe !=="AED" && ae.service=== service))
	const appelEleves = appel.appelEleves.filter(ae => (ae.eleve.classe !=="AED"))


	function tableClick(table){
		setTable(table)
		setPlace(null)
	}


	return(
		<div>

			<Button
				className="me-2"
				variant={ service===1 ? 'primary'  : "secondary" }
				onClick={()=>setService(1)}
			>
				1er service
			</Button>
			<Button
				variant={ service===2 ? 'success'  : "secondary" }
				onClick={()=>setService(2)}
			>
				2e service
			</Button>

			<br /><br />
			<BoutonTable table="1"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="2"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="3"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="4"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="5"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="6"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="7"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="8"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="9"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="10"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="11"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="12"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="13"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />
			<BoutonTable table="14"  selectedTable={table} setSelectedTable={setTable} setPlace={setPlace} appelEleves={appelEleves} />


			<h5>Table {table}</h5>
			{table &&
				<StyledTable>
					<div className="duo">
						<Place table={table} num="1"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P1`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="2"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P2`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="3"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P3`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="4"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P4`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="5"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P5`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="6"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P6`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="7"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P7`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="8"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P8`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo duo-separated">
						<Place table={table} num="9"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P9`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="10"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P10`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="11"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P11`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="12"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P12`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="13"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P13`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="14"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P14`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
					<div className="duo">
						<Place table={table} num="15"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P15`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
						<Place table={table} num="16"	appelEleves={appelEleves.filter(ae => ae.place===`T${table}P16`)}  place={place} setPlace={setPlace}	updateAe={updateAe}/>
					</div>
				</StyledTable>
			}
			<br />

			<BoutonFiltreNiveau niv="6" niveau={niveau} setNiveau={setNiveau} />
			<BoutonFiltreNiveau niv="5" niveau={niveau} setNiveau={setNiveau} />
			<BoutonFiltreNiveau niv="4" niveau={niveau} setNiveau={setNiveau} />
			<BoutonFiltreNiveau niv="3" niveau={niveau} setNiveau={setNiveau} />

			<br /><br />


			{ appelEleves.filter(ae => ( ae.service=== service && (niveau===null || ae.eleve.classe.startsWith(niveau) ))).map((ae) => {
				return(
					<StyledEleve key={"azeazdeaze"+ae.id}
						onClick={()=>boutonPlacerEleveClick(ae)}
						 className={`btn  btn-${place ? "" : "outline-"}${   ae.place ? "secondary" :  (service===1 ? "primary" : "success")}`}
					>
						<b>{ae.eleve.nom}</b> {ae.eleve.prenom} -  {ae.eleve.classe} {ae.place && " - ["+ae.place+"]"}
					</StyledEleve>
				)
			})}
		</div>
	)



		function boutonPlacerEleveClick(ae){
			if (place===null){
				alert("Cliquez d'abord sur une place")
				return null
			}
			if (ae.place!==null){
				if(window.confirm(`\r\n\r\n${ae.eleve.nom} ${ae.eleve.prenom} est déjà à la place  "${ae.place}"  \r\n\r\n Voulez-vous le déplacer cet élève?`)){
				}
				else{
					return null
				}
			}

			//			ae={...ae, placeIsLoading: true  }
			updateAe({...ae, place: place  })
			setPlace(null)
		}


}

//█████████████████████████████████████████████████████████████

function BoutonFiltreNiveau({niv, niveau, setNiveau}) {
	return(
		<Button
			className="me-1"
			variant={ niveau===niv ? 'info'  : "secondary" }
			onClick={()=>{setNiveau( niveau===niv ? null : niv)}}
		>
			{niv}e
		</Button>
	)
}

//█████████████████████████████████████████████████████████████ StyledComponents

const StyledTable = styled.div`
	/*background-color:yellow;*/
	width:100%;

	.duo{
		/*border: 1px solid green;*/
		width:12.5%;
		display: inline-block;
		min-height: 60px;
	}
	.duo-separated{
		border-left: 3px solid black;
	}
`;


const StyledEleve = styled.div`
	/*background-color:cyan; */
	border: 1px solid blue;
	width:100%;
	height: 50%;
	overflow: hidden;
    white-space: nowrap;
	font-size:1em;
	padding: 5px;
	margin-bottom: 3px;
`;
